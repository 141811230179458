import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

declare const bootstrap: any;

function InputCopyClipboard({ text }: any) {
  const { t } = useTranslation();
  const tooltip = useRef(null);
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(text);

      setContentTooltip(t('users-page.modal-add-user.copied'));
      setIsCopied(true);

      setTimeout(() => {
        setContentTooltip(t('users-page.modal-add-user.clipboard-alert'));
        setIsCopied(false);
      }, 2000);
    } catch (error) {
      console.error('Error al copiar al portapapeles:', error);
      setContentTooltip(t('users-page.modal-add-user.error-copy'));
    }
  };

  const setContentTooltip = (tooltipText: string) => {
    try {
      const tooltipInstance = bootstrap.Tooltip.getInstance(tooltip.current);
      if (tooltipInstance) {
        tooltipInstance.setContent({ '.tooltip-inner': tooltipText });
      }
    } catch (error) {
      console.error('Error al actualizar el tooltip:', error);
    }
  };

  useEffect(() => {
    if (tooltip.current) {
      new bootstrap.Tooltip(tooltip.current);
    }
  }, []);

  return (
    <div className="input-group">
      <input
        id="kt_clipboard_1"
        type="text"
        className="form-control text-muted text-center"
        readOnly
        value={text}
      />
      <button
        className="btn btn-light-primary m-0"
        data-bs-toggle="tooltip"
        title={t('users-page.modal-add-user.clipboard-alert')}
        onClick={copyToClipboard}
        ref={tooltip}
      >
        <i className={isCopied ? 'bi-check' : 'bi-clipboard'}></i>
      </button>
    </div>
  );
}

export default InputCopyClipboard;
