import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useModal from '../../hooks/close-modal.hook';
import { Controller, useForm } from 'react-hook-form';
import Button from '../../components/forms/button.component';
import Select from 'react-select';
import Input from '../../components/forms/input.component';
import Title from '../../components/forms/titles.component';
import InvalidFeedback from '../../components/forms/invalid-feedback.component';
import { CompaniesService } from '../../services/companies.service';
import { Areas } from '../../services/area.service';
import { BlackListService } from '../../services/blacklist.service';
import { AlertService } from '../../services/alert.service';
import { usePapaParse } from 'react-papaparse';

interface AddBlackListProps {
  show: boolean;
  onRequestClose: (refresh: boolean) => void;
}

function AddBlackList({ show, onRequestClose }: AddBlackListProps) {
  const { t } = useTranslation();
  const regex = /^\d{10}$/;
  const modalRef = useRef<HTMLDivElement>(null);
  const [companies, setCompanies] = useState<Array<any>>([]);
  const [areas, setAreas] = useState<any>([]);
  const { readString } = usePapaParse();

  useModal(modalRef, show);

  const defaultValues = {
    name: '',
    area: '',
    separator: 'tab',
    csv: '',
    csv_input: '',
    company: '',
    phone_numbers: 0
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isValid, isSubmitting }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const company = watch('company');

  const closeModal = () => {
    reset(defaultValues);
    onRequestClose(false);
  };

  const handleCsvChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type === 'text/csv') {
      processCsvFile(file);
    } else {
      AlertService.toastError(t('blacklist-page.csv-error'));
      setValue('csv', '');
      setValue('csv_input', '');
    }
  };

  const processCsvFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const csvData = event.target?.result as string;
      readString(csvData, {
        header: false,
        skipEmptyLines: true,
        complete: (result: any) => {
          const phoneNumbers = result.data
            .map((row: any) => row[0]?.replace(/\D/g, ''))
            .filter((phoneNumber: string) => regex.test(phoneNumber));
          if (phoneNumbers.length === 0) {
            AlertService.toastError(t('blacklist-page.csv-error-empty'));
            setValue('csv', '');
            setValue('csv_input', '');
            setValue('phone_numbers', 0);
          } else {
            const csvContent = phoneNumbers.map((num: string) => num).join('\n');
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const updatedFile = new File([blob], 'filtered_numbers.csv', { type: 'text/csv' });
            setValue('csv', updatedFile as any);
            setValue('phone_numbers', phoneNumbers.length);
          }
        }
      });
    };
    reader.readAsText(file);
  };

  const submit = async (data: any) => {
    if (!watch('csv')) {
      AlertService.toastError(t('blacklist-page.csv-error'));
      return;
    }

    const confirmed = await AlertService.question(
      t('general.confirmation'),
      t('blacklist-page.confirm-upload', { count: watch('phone_numbers') }),
      () => Promise.resolve(),
      t('general.upload')
    );

    if (!confirmed) return;

    try {
      const formData = new FormData();
      formData.append('name', data.name);
      formData.append('area', data.area);
      formData.append('separator', data.separator);
      formData.append('csv', data.csv);
      await BlackListService.saveBlackList(formData);
      reset(defaultValues);
      AlertService.toastSuccess(t('blacklist-page.success-upload'));
      onRequestClose(true);
    } catch (error) {
      console.error(error);
      AlertService.toastError(t('blacklist-page.error-upload'));
    }
  };

  const getCompanies = async () => {
    const data = await CompaniesService.getCompanies();
    if (data) {
      const mappedCompanies = data.map((item: any) => ({
        value: item._id,
        label: item.name
      }));
      setCompanies(mappedCompanies);
    } else {
      setCompanies([]);
    }
  };

  const getAreas = async () => {
    if (company) {
      const data = await Areas.getAreas({ company: company });
      const mappedAreas = data.map((item: any) => ({
        value: item._id,
        label: item.name
      }));
      setAreas(mappedAreas);
    } else {
      setAreas([]);
    }
  };

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {
    getAreas();
  }, [company]);

  return (
    <div aria-hidden="true" className="modal fade" ref={modalRef} data-bs-backdrop="static">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header justify-content-end border-0 pb-0">
            <div
              className="btn btn-sm btn-icon btn-active-light-primary"
              onClick={() => closeModal()}
            >
              <i className="ki-duotone ki-cross fs-2x">
                <span className="path1" />
                <span className="path2" />
              </i>
            </div>
          </div>
          <form onSubmit={handleSubmit(submit)}>
            <div className="modal-body pt-0 pb-15 px-5 px-xl-20">
              <div className="mt-1 text-center">
                <h1>{t('blacklist-page.add-blacklist')}</h1>
                <div className="text-muted fw-semibold fs-5">
                  <span
                    dangerouslySetInnerHTML={{ __html: t('channels.sms.modal-C-Subtitle') }}
                  ></span>
                </div>
                <div className="separator border-2 my-5"></div>
              </div>
              <div className="row mt-10">
                <div className="col-lg-8 mb-10 mb-lg-0 h-100 p-10">
                  <Title
                    title={t('blacklist-page.blacklist-data-title')}
                    subtitle={t('blacklist-page.blacklist-data-text')}
                    icon="ki-notepad-edit"
                  />
                  <div>
                    <div className="fv-row mb-7">
                      <Input
                        label={t('blacklist-page.name-label')}
                        requiredIndicator="required"
                        placeholder={t('blacklist-page.name-placeholder')}
                        errors={errors.name}
                        {...register('name', {
                          required: true,
                          setValueAs: (value) => value.trim()
                        })}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="fv-row mb-7">
                        <label className="form-label required">
                          {t('blacklist-page.company-label')}
                        </label>
                        <Controller
                          control={control}
                          name="company"
                          rules={{ required: true }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <>
                              <Select
                                options={companies}
                                noOptionsMessage={() => (
                                  <div className="empty-select">{t('general.no-options')}</div>
                                )}
                                className={
                                  error ? 'form-select-custom is-invalid' : 'form-select-custom'
                                }
                                classNamePrefix="form-select-custom"
                                placeholder={t('blacklist-page.company-placeholder')}
                                onChange={(
                                  selectedOption: { value: string; label: string } | null
                                ) => {
                                  onChange(selectedOption ? selectedOption.value : null);
                                  setValue('company', selectedOption ? selectedOption.value : '');
                                }}
                                value={
                                  companies.find((company: any) => company.value === value) || null
                                }
                              />
                              <InvalidFeedback error={error} />
                            </>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="fv-row mb-7">
                      <label className="form-label required">
                        {t('blacklist-page.area-label')}
                      </label>
                      <Controller
                        control={control}
                        name="area"
                        rules={{ required: true }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <>
                            <Select
                              options={areas}
                              isDisabled={!company ? true : false}
                              noOptionsMessage={() => (
                                <div className="empty-select">{t('general.no-options')}</div>
                              )}
                              className="form-select-custom"
                              classNamePrefix="form-select-custom"
                              placeholder={t('blacklist-page.area-placeholder')}
                              onChange={(
                                selectedOption: { value: string; label: string } | null
                              ) => {
                                onChange(selectedOption ? selectedOption.value : null);
                              }}
                              value={
                                areas?.find(
                                  (profileOption: any) => profileOption.value === value
                                ) || null
                              }
                            />
                            <InvalidFeedback error={error} />
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="fv-row">
                    <Input
                      type="file"
                      label={t('blacklist-page.csv-label')}
                      requiredIndicator="required"
                      errors={errors.csv_input}
                      accept=".csv"
                      {...register('csv_input', {
                        required: true
                      })}
                      onChange={handleCsvChange}
                    />
                  </div>
                </div>
                <div className="col-lg-4 bg-light rounded d-flex flex-column justify-content-center align-items-center text-center">
                  <span className="symbol symbol-circle symbol-50px mb-6">
                    <span className="symbol-label bg-light-warning">
                      <i className="ki-duotone ki-information-3 fs-2x text-warning">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                      </i>
                    </span>
                  </span>
                  <h4 className="fw-bold mb-4">{t('blacklist-page.warning-title')}</h4>
                  <p className="text-muted fw-semibold text-justify">
                    {t('blacklist-page.warning-text')}
                    <br />
                    {t('blacklist-page.warning-text-1')}
                  </p>
                  <img
                    src="/assets/media/examples/blacklist_example.png"
                    className="img-fluid"
                    width={'150px'}
                  />
                </div>
              </div>
              <div className="d-flex flex-center flex-row-fluid pt-12">
                <button
                  type="button"
                  className="btn btn-light hover-scale"
                  onClick={() => closeModal()}
                >
                  {t('general.close')}
                </button>
                <Button
                  type="submit"
                  id="sendData"
                  className="btn btn-primary ms-3 hover-scale"
                  disabled={!isValid}
                  isLoading={isSubmitting}
                >
                  {t('general.btn-add-modal')}
                  <i className="ki-duotone ki-send ms-2 fs-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddBlackList;
