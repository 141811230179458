import { useRef, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ChannelsService } from '../../../services/channels.service';
import { AlertService } from '../../../services/alert.service';
import Select from 'react-select';
import Input from '../../../components/forms/input.component';
import Switch from '../../../components/forms/switch.component';
import Button from '../../../components/forms/button.component';
import Title from '../../../components/forms/titles.component';
import InvalidFeedback from '../../../components/forms/invalid-feedback.component';
import useInputValidation from '../../../hooks/input-validation.hook';
import useModal from '../../../hooks/close-modal.hook';

interface ProviderSettings {
  required: boolean;
  type: string;
}

interface ProviderOption {
  value: string;
  label: string;
  type: string;
  settings: { [key: string]: ProviderSettings };
}

interface AgentModalProps {
  providers: ProviderOption[];
  show: boolean;
  onRequestClose: (refresh: boolean) => void;
  payload: any;
}

function AgentModal({ payload, show, providers, onRequestClose }: AgentModalProps) {
  const { t } = useTranslation();
  const { numericRegex, handleNumericInput } = useInputValidation();
  const [selectedProvider, setSelectedProvider] = useState<ProviderOption | null>(null);
  const [dynamicSettings, setDynamicSettings] = useState<{ [key: string]: any }>({});
  const modalRef = useRef<HTMLDivElement>(null);
  useModal(modalRef, show);

  const defaultValues = {
    _id: '',
    name: '',
    provider: '',
    settings: {},
    telcel: false,
    movistar: false,
    att: false,
    other: false,
    carriers: [] as string[]
  };

  const {
    register,
    control,
    watch,
    reset,
    handleSubmit,
    formState: { errors, isValid, isSubmitting }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const telcelShow = watch('telcel');
  const movistarShow = watch('movistar');
  const attShow = watch('att');
  const altanShow = watch('other');
  const isAnySwitchActive = telcelShow || movistarShow || attShow || altanShow;

  const handleProviderChange = (selectedOption: ProviderOption | null) => {
    setSelectedProvider(selectedOption);

    if (selectedOption) {
      const newSettings: { [key: string]: any } = {};
      Object.keys(selectedOption.settings).forEach((key) => {
        newSettings[key] = '';
      });
      setDynamicSettings(newSettings);
    }
  };

  const registerAgent = async (data: any) => {
    const { name, provider, telcel, movistar, att, other, carriers } = data;
    const settingsData: { [key: string]: any } = {};
    Object.keys(selectedProvider?.settings || {}).forEach((keyParam) => {
      settingsData[keyParam] = data[keyParam];
    });

    const updatedCarriers = [
      ...(telcel ? ['telcel'] : []),
      ...(movistar ? ['movistar'] : []),
      ...(att ? ['att'] : []),
      ...(other ? ['other'] : [])
    ];

    const dataSend: { [key: string]: any } = {
      name,
      provider,
      settings: settingsData,
      carriers: updatedCarriers
    };

    if (payload != null) {
      dataSend._id = payload._id;
    }
    try {
      if (payload != null) {
        await ChannelsService.updateAgentsSMS(dataSend);
        AlertService.toastSuccess(t('channels.sms.modal-C-succesEdit2'));
      } else {
        await ChannelsService.addAgentsSMS(dataSend);
        AlertService.toastSuccess(t('channels.sms.modal-C-succes2'));
      }
      await onRequestClose(true);
      reset(defaultValues);
    } catch (error) {
      AlertService.toastError(`${t('general.atention')} ${error}`);
    }
  };

  const resetForm = () => {
    onRequestClose(false);
    setSelectedProvider(null);
    reset(defaultValues);
  };

  useEffect(() => {
    if (payload) {
      const newSettings: { [key: string]: any } = {};
      if (payload.provider && providers.length) {
        const selectedProvider = providers.find((provider) => provider.value === payload.provider);
        if (selectedProvider) {
          Object.keys(selectedProvider.settings).forEach((key) => {
            newSettings[key] = payload.settings?.[key] || '';
          });
          setSelectedProvider(selectedProvider);
          setDynamicSettings(newSettings);
        }
      }
      reset({
        _id: payload?._id ? payload._id : defaultValues._id,
        name: payload.name || defaultValues.name,
        provider: payload.provider || defaultValues.provider,
        telcel: payload.carriers.includes('telcel') || defaultValues.telcel,
        movistar: payload.carriers.includes('movistar') || defaultValues.movistar,
        att: payload.carriers.includes('att') || defaultValues.att,
        other: payload.carriers.includes('other') || defaultValues.other,
        carriers: payload.carriers || defaultValues.carriers,
        ...newSettings
      });
    }
  }, [payload]);

  return (
    <div className="modal fade" ref={modalRef} data-bs-backdrop="static">
      <div className="modal-dialog modal-xl">
        <div className="modal-content rounded">
          <div className="modal-header justify-content-end border-0 pb-0">
            <div
              className="btn btn-sm btn-icon btn-active-light-primary"
              onClick={() => resetForm()}
            >
              <i className="ki-duotone ki-cross fs-2x">
                <span className="path1" />
                <span className="path2" />
              </i>
            </div>
          </div>
          <form onSubmit={handleSubmit(registerAgent)}>
            <div className="modal-body pt-0 pb-15 px-5 px-xl-20">
              <div className="mt-1 text-center">
                <h1 className="mb-3">
                  {watch('_id') ? (
                    <>
                      {t('channels.sms.modal-C-titleEdit2')}
                      <a className="link-primary fw-bold ms-2" href="#">
                        {payload?.name}
                      </a>
                    </>
                  ) : (
                    <> {t('channels.sms.modal-C-title2')}</>
                  )}
                </h1>
                <div className="text-muted fw-semibold fs-5">
                  <span
                    dangerouslySetInnerHTML={{ __html: t('channels.sms.modal-C-Subtitle') }}
                  ></span>
                </div>
                <div className="separator border-2 my-5"></div>
              </div>
              <div className="row mt-10">
                <div className="col-lg-6 mb-10 mb-lg-0  h-100 p-10">
                  <Title
                    title={t('channels.sms.modal-C-seccion1-title')}
                    subtitle={t('channels.sms.modal-C-seccion1-Subtitle2')}
                    icon="ki-pencil"
                    type="info"
                  />
                  <div className="fv-row mb-15">
                    <Input
                      label={t('channels.sms.tc-name')}
                      requiredIndicator="required"
                      placeholder={t('channels.sms.modal-C-seccion1-placeholder2')}
                      errors={errors.name}
                      {...register('name', { required: true })}
                    />
                  </div>
                  <Title
                    title={t('channels.sms.modal-C-seccion2-title')}
                    subtitle={t('channels.sms.modal-C-seccion2-Subtitle2')}
                    icon="ki-satellite"
                    type="info"
                    required
                  />
                  <div className="row mb-7">
                    <div className="col-md-6">
                      <div className="d-flex flex-stack align-items-center mb-5">
                        <div className="d-flex align-items-center me-2">
                          <div className="d-flex flex-column me-6">
                            <div className="fw-bold fs-6 theme-light-show">
                              <img src="/assets/media/operators/telcel.png" alt="" width="70px" />
                            </div>
                            <div className="fw-bold fs-6 theme-dark-show">
                              <img
                                src="/assets/media/operators/telcel-dark.png"
                                alt=""
                                width="70px"
                              />
                            </div>
                            <span className="fs-7 text-muted"></span>
                          </div>
                          <div className="form-check form-check-custom form-check-solid ms-auto">
                            <Switch {...register('telcel')} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex flex-stack align-items-center">
                        <div className="d-flex align-items-center me-2">
                          <div className="d-flex flex-column me-6">
                            <div className="fw-bold fs-6 theme-light-show">
                              <img src="/assets/media/operators/movistar.png" alt="" width="70px" />
                            </div>
                            <div className="fw-bold fs-6 theme-dark-show">
                              <img
                                src="/assets/media/operators/movistar-dark.png"
                                alt=""
                                width="70px"
                              />
                            </div>
                            <span className="fs-7 text-muted"></span>
                          </div>
                          <div className="form-check form-check-custom form-check-solid ms-auto">
                            <Switch {...register('movistar')} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="d-flex flex-stack align-items-center">
                        <div className="d-flex align-items-center me-2">
                          <div className="d-flex flex-column me-6">
                            <div className="fw-bold fs-6 theme-light-show">
                              <img src="/assets/media/operators/att.png" alt="" width="70px" />
                            </div>
                            <div className="fw-bold fs-6 theme-dark-show">
                              <img src="/assets/media/operators/att-dark.png" alt="" width="70px" />
                            </div>
                            <span className="fs-7 text-muted"></span>
                          </div>
                          <div className="form-check form-check-custom form-check-solid ms-auto">
                            <Switch {...register('att')} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex flex-stack align-items-center">
                        <div className="d-flex align-items-center me-2">
                          <div className="d-flex flex-column me-6">
                            <div className="fw-bold fs-6 theme-light-show">
                              <img src="/assets/media/operators/altan.png" alt="" width="70px" />
                            </div>
                            <div className="fw-bold fs-6 theme-dark-show">
                              <img
                                src="/assets/media/operators/altan-dark.png"
                                alt=""
                                width="70px"
                              />
                            </div>
                            <span className="fs-7 text-muted"></span>
                          </div>
                          <div className="form-check form-check-custom form-check-solid ms-auto">
                            <Switch {...register('other')} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 rounded h-100 bg-light-primary p-10">
                  <Title
                    title={t('channels.sms.agent-tc-2')}
                    subtitle={t('channels.sms.modal-C-provider-sub')}
                    icon="ki-icon"
                  />
                  <div className="fv-row mb-10">
                    <label className="form-label required">{t('channels.sms.agent-tc-2')}</label>
                    <Controller
                      control={control}
                      name="provider"
                      rules={{ required: true }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => {
                        const filteredProviders = providers.filter(
                          (provider) => provider.type === 'sms'
                        );
                        return (
                          <>
                            <Select
                              options={filteredProviders}
                              noOptionsMessage={() => (
                                <div className="empty-select">{t('general.no-options')}</div>
                              )}
                              className={
                                error ? 'form-select-custom is-invalid' : 'form-select-custom'
                              }
                              classNamePrefix="form-select-custom"
                              placeholder={t('channels.sms.modal-C-provider-placeholder')}
                              onChange={(selectedOption) => {
                                if (selectedOption) {
                                  onChange(selectedOption.value);
                                  handleProviderChange(selectedOption);
                                }
                              }}
                              value={
                                filteredProviders.find((option) => option.value === value) || null
                              }
                            />
                            <InvalidFeedback error={error} />
                          </>
                        );
                      }}
                    />
                  </div>
                  {selectedProvider && (
                    <>
                      <Title
                        title={t('channels.sms.modal-C-settings-title')}
                        subtitle={t('channels.sms.modal-C-settings-subtitle')}
                        icon="ki-setting-2"
                        type="danger"
                      />
                      {Object.entries(selectedProvider.settings).map(
                        ([keyParam, settingsObj], index) => (
                          <div key={index} className="fv-row mb-7">
                            <Input
                              key={keyParam}
                              label={keyParam}
                              requiredIndicator={settingsObj.required ? 'required' : ''}
                              optional={!settingsObj.required}
                              placeholder={t('channels.sms.modal-C-settings-add', {
                                setting: keyParam
                              })}
                              onKeyDown={
                                settingsObj.type === 'number' ? handleNumericInput : undefined
                              }
                              {...register(keyParam as any, {
                                required: settingsObj.required || false,
                                pattern:
                                  settingsObj.type === 'number'
                                    ? {
                                        value: numericRegex,
                                        message: 'Debe ingresar solo números'
                                      }
                                    : undefined,
                                value: dynamicSettings[keyParam] || ''
                              })}
                            />
                          </div>
                        )
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="d-flex flex-center flex-row-fluid pt-12">
                <button
                  type="button"
                  className="btn btn-light hover-scale"
                  onClick={() => resetForm()}
                >
                  {t('general.close')}
                </button>
                <Button
                  type="submit"
                  id="sendData"
                  className="btn btn-primary ms-3 hover-scale"
                  disabled={!isValid || !isAnySwitchActive}
                  isLoading={isSubmitting}
                >
                  {watch('_id') ? t('general.edit') : t('channels.sms.modal-C-seccion2-btnsubmit')}
                  <i className="ki-duotone ki-send ms-2 fs-1">
                    <span className="path1" />
                    <span className="path2" />
                  </i>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AgentModal;
