import { Outlet, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import useHasAccess from '../hooks/use-has-access.hook';

interface HasAccessRouteProps {
  onlyRoot?: boolean;
  view?: string[];
}

function HasAccessRoute({ onlyRoot, view }: HasAccessRouteProps) {
  const navigate = useNavigate();

  const { isAllowed } = useHasAccess({ view });

  useEffect(() => {
    if (isAllowed === false) {
      navigate('/404');
    }
  }, [isAllowed]);

  return <Outlet />;
}
export default HasAccessRoute;
