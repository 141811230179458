import axios from 'axios';
import { STORAGE } from '../constants';
import { LocalStorageService } from './local-storage.service';
import i18n from 'i18next';

const _HTTP_ = axios.create();

const getErrorStatus = (url: string): string => {
  const domain = url.substring(url.indexOf('//') + 2, url.indexOf('/', url.indexOf('//') + 2));
  if (domain === 'api.chatbridge.mx') {
    return 'Crítico';
  }
  if (domain === 'dev-api.chatbridge.mx') {
    return 'Medio';
  }
  return 'Desconocido';
};

_HTTP_.interceptors.request.use((config) => {
  const token = LocalStorageService.get(STORAGE.TOKEN);
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

_HTTP_.interceptors.response.use(
  async (response) => {
    return response.data;
  },
  async (error) => {
    const { statusCode } = error.response.data;
    const { message } = error.response.data;
    if (statusCode === 500) {
      try {
        if (error.config.url) {
          const parsedUrl = new URL(error.config.url);
          const hostname = parsedUrl.hostname;
          const errorStatus = getErrorStatus(error.config.url);
          const environment =
            errorStatus === 'Medio'
              ? 'Desarrollo'
              : errorStatus === 'Medio'
                ? 'Producción'
                : 'Desconocido';
          console.log(hostname);
          const telegramApiUrl = `https://api.telegram.org/bot8111102806:AAGKJKQlvsS0ScVlcKQ2Z67nbnV7o609DN0/sendMessage`;
          const message =
            `¡ Atención ! , EndPoint Error:\n\n` +
            `EndPoint: ${error.config.url}\n` +
            `Ambiente: ${environment}\n` +
            `Error: 500\n` +
            `Estado: ${errorStatus}\n` +
            `Message: ${error.statusText}\n\n` +
            `Verificar EndPoint`;

          const params = {
            chat_id: '-4533930463',
            text: message
          };
          await axios.post(telegramApiUrl, params);
        }
      } catch (telegramError) {
        console.error('Error al enviar mensaje a Telegram:', telegramError);
      }
    }
    return handleResponseCode(statusCode, message);
  }
);

const handleResponseCode = (code: number, errorMessage: any) => {
  const t = i18n.t.bind(i18n);

  switch (code) {
    case 400:
      if (errorMessage === 'Usuario y/o contraseña incorrectos') {
        errorMessage = t('errors.alerts-erros-01');
      }
      return Promise.reject(errorMessage);
    case 500:
      if (errorMessage.toLowerCase() === 'sesión caducada') {
        window.location.replace('/auth/login');
        LocalStorageService.clear();
        return;
      }
      if (errorMessage === 'Internal server error') {
        errorMessage = t('errors.error-500');
      }
      return Promise.reject(errorMessage || { message: t('errors.unknown') });
    case 401:
      window.location.replace('/auth/login');
      LocalStorageService.clear();
      break;
  }
};

export const HTTP = _HTTP_;
