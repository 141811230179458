import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../components/layout/header.component';
import Asaid from '../../components/layout/asaid.component';
import Footer from '../../components/layout/footer.component';
import useBgPages from '../../hooks/bg-pages.hook';
import useRequest from '../../hooks/use-request.hook';
import { CompaniesService } from '../../services/companies.service';
import Datatable from '../../components/datatable/datatable.component';
import EditCompany from './edit-companie.modal';
import AddCompany from './add-companie.modal';
import moment from 'moment';

interface Data {
  _id: string;
  created_at: string;
  status: boolean;
}
function CompaniesPage() {
  useBgPages();
  const { t } = useTranslation();
  const { call, isRequesting } = useRequest();
  const [row, setRows] = useState<Data[]>([]);
  const [totalCompanies, setTotalCompanies] = useState();
  const [activeCompanies, setActiveCompanies] = useState();
  const columns = [
    {
      name: t('companies-page.title-column-1'),
      cell: (row: any) => {
        return <h5>{row.name}</h5>;
      }
    },
    {
      name: t('companies-page.title-column-2'),
      cell: (row: any) => {
        const fechaHora = moment(row.created_at);
        const fecha = fechaHora.format('DD-MM-YYYY');
        const hora = fechaHora.format('HH:mm:ss');

        return (
          <div className="text-nowrap">
            <h5>
              {t('general.date')}: <span className="text-muted">{fecha}</span>
            </h5>
            <h5>
              {t('general.hour')}: <span className="text-muted">{hora}</span>
            </h5>
          </div>
        );
      }
    },
    {
      name: t('companies-page.title-column-3'),
      cell: (row: any) =>
        row.status ? (
          <span className="badge badge-light-primary fs-6">
            <i className="ki-duotone ki-check-circle text-primary fs-3">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            {t('general.active')}
          </span>
        ) : (
          <span className="badge badge-light-danger fs-6">
            <i className="ki-duotone ki-minus-circle text-danger fs-3">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            {t('general.inactive')}
          </span>
        )
    },
    {
      name: t('companies-page.title-column-4'),
      sortable: false,
      center: true,
      exportable: false,
      cell: (row: any) => {
        return (
          <>
            <div>
              <button
                className="btn btn-light-primary btn-sm btn-flex btn-center"
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end"
                type="button"
              >
                {t('general.acctions')}
                <i className="ki-duotone ki-down fs-5 ms-1" />
              </button>
              <div
                className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                data-kt-menu="true"
              >
                <div className="menu-item px-3">
                  <a
                    className="menu-link px-3"
                    href="#"
                    onClick={() => setShowModal1({ show: true, data: row })}
                  >
                    <i className="ki-duotone ki-notepad-edit fs-2x text-primary">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span className="ms-2">{t('general.edit')}</span>
                  </a>
                </div>
              </div>
            </div>
          </>
        );
      }
    }
  ];
  const getCompanies = async () => {
    try {
      const data = await call(CompaniesService.getCompanies);
      setRows(data);
      setTotalCompanies(data.length);
      const active = data.filter((x: any) => x.status === true);
      setActiveCompanies(active.length);
    } catch (error) {
      console.error(t('general.error'), error);
    }
  };

  const [showModal, setShowModal] = useState({
    show: false,
    data: null
  });

  const handleShowModal = async (refresh: boolean) => {
    if (refresh) {
      await getCompanies();
    }
    setShowModal({ show: false, data: null });
  };

  const [showModal1, setShowModal1] = useState({
    show: false,
    data: null
  });

  const handleShowModal1 = async (refresh: boolean) => {
    if (refresh) {
      await getCompanies();
    }
    setShowModal1({ show: false, data: null });
  };

  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <>
      <div className="page d-flex flex-row flex-column-fluid">
        <Asaid />
        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <Header />
          <div className="content fs-6 d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="toolbar" id="kt_toolbar">
              <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                <div className="d-flex flex-column align-items-start justify-content-center flex-wrap me-2">
                  <ul className="breadcrumb fw-semibold fs-base my-1">
                    <li className="breadcrumb-item text-muted">
                      {t('asaid.link-title-operations')}
                    </li>
                  </ul>
                  <h1 className="fw-bold fw-bold my-1 fs-2">
                    <span className="d-flex align-items-center">
                      <i className="ki-duotone ki-briefcase fs-3x">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                        <span className="path5"></span>
                        <span className="path6"></span>
                      </i>
                      <span className="ms-3">{t('asaid.link-companies')}</span>
                    </span>
                  </h1>
                </div>
              </div>
            </div>
            <div className="post fs-6 d-flex flex-column-fluid" id="kt_post">
              <div className="container-xxl">
                <div className="row gy-5 g-xl-10">
                  {/*Tarjeta Estadísticas*/}
                  <div className="col-xl-4 mb-xl-10">
                    <div className="card mb-5 mb-xxl-8">
                      <div className="card-header align-items-center border-0 mt-5">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="fw-bolder fw-bold fs-2">
                            {t('companies-page.title-statistics')}
                          </span>
                          <span className="text-gray-400 mt-2 fw-semibold fs-6">
                            {t('companies-page.title-companies')}
                          </span>
                        </h3>
                        <div className="card-toolbar">
                          <span className="btn btn-clean btn-sm btn-icon btn-icon-primary me-n3">
                            <i className="ki-duotone ki-category fs-1 text-primary">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                              <span className="path4" />
                            </i>
                          </span>
                        </div>
                      </div>
                      <div className="card-body pt-1">
                        <div className="d-flex flex-stack mb-2 item-border-hover px-3 py-2 ms-n3">
                          <div className="d-flex align-items-center">
                            <i className="ki-duotone ki-book-open fs-3x text-info w-35px me-6 hover-scale">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                              <span className="path4" />
                            </i>
                            <div className="pe-2">
                              <a
                                title="s"
                                className="fs-5 text-gray-800 text-hover-info fw-bolder"
                                href="#"
                              >
                                {t('companies-page.title-my-companies')}
                              </a>
                              <div className="text-gray-400 fw-semibold mt-1">
                                {t('companies-page.item-my-companies')}
                              </div>
                            </div>
                          </div>
                          <span className="label rounded bg-light w-25px h-25px fs-5 fw-bolder ">
                            {totalCompanies}
                          </span>
                        </div>
                        <div className="d-flex flex-stack mb-10 item-border-hover px-3 py-2 ms-n3">
                          <div className="d-flex align-items-center">
                            <i className="ki-duotone ki-check-circle fs-3x text-primary w-35px me-6 hover-scale">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                              <span className="path4" />
                            </i>
                            <div className="pe-2">
                              <a
                                title="s"
                                className="fs-5 text-gray-800 text-hover-primary fw-bolder"
                                href="#"
                              >
                                {t('companies-page.title-active')}
                              </a>
                              <div className="text-gray-400 fw-semibold mt-1">
                                {t('companies-page.item-active')}
                              </div>
                            </div>
                          </div>
                          <span className="label rounded bg-light w-25px h-25px fs-5 fw-bolder">
                            {activeCompanies}
                          </span>
                        </div>
                        <div className="d-flex align-items-center flex-nowrap text-nowrap py-1">
                          <a
                            href="#"
                            className="btn btn-primary hover-scale"
                            onClick={() => setShowModal({ show: true, data: null })}
                          >
                            <i className="ki-duotone ki-abstract-10 fs-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                            {t('companies-page.add-company-btn')}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*TABLA*/}
                  <div className="col-xl-8 mb-5 mb-xl-10">
                    <div className="row g-5 g-xl-10 h-xxl-50 mb-0 mb-xl-10">
                      <div className="card mb-5 mb-xxl-8">
                        <div className="card-header align-items-center border-0 mt-5">
                          <h3 className="card-title align-items-start flex-column">
                            <span className="fw-bolder fw-bold fs-2">
                              {t('asaid.link-companies')}
                            </span>
                            <span className="text-gray-400 mt-2 fw-semibold fs-6">
                              {t('companies-page.registered')}
                            </span>
                          </h3>
                        </div>
                        <div className="card-body pt-1">
                          <Datatable columns={columns} data={row} isLoading={isRequesting} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <AddCompany
        show={showModal.show}
        onRequestClose={(refresh: any) => handleShowModal(refresh)}
      />
      <EditCompany
        show={showModal1.show}
        onRequestClose={(refresh: any) => handleShowModal1(refresh)}
        payload={showModal1.data}
      />
    </>
  );
}

export default CompaniesPage;
