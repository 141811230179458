import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HasAccessView from '../../guard/has-access-view';
import { PROFILES } from '../../constants';

function Aside() {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <>
      <div
        id="kt_aside"
        className="aside aside-default aside-hoverable"
        data-kt-drawer="true"
        data-kt-drawer-name="aside"
        data-kt-drawer-activate="{default: true, lg: false}"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="{default:'200px', '300px': '250px'}"
        data-kt-drawer-direction="start"
        data-kt-drawer-toggle="#kt_aside_toggle"
      >
        <div className="aside-logo flex-column-auto px-10 pt-9 pb-5" id="kt_aside_logo">
          <Link to="/sendCampaigns">
            <img
              alt="Logo"
              src="/assets/media/logos/ch-l.svg"
              className="max-h-60px w-100px logo-default theme-light-show"
            />
            <img
              alt="Logo"
              src="/assets/media/logos/ch-l-dark.svg"
              className="max-h-60px w-100px logo-default theme-dark-show"
            />
            <img alt="Logo" src="/assets/media/logos/logo-c.svg" className="w-65px logo-minimize" />
          </Link>
        </div>
        <div className="aside-menu flex-column-fluid ps-3 pe-1">
          <div
            className="menu menu-sub-indention menu-column menu-rounded menu-title-gray-600 menu-icon-gray-500 menu-active-bg menu-state-primary menu-arrow-gray-500 fw-semibold fs-6 my-5 mt-lg-2 mb-lg-0"
            id="kt_aside_menu"
            data-kt-menu="true"
          >
            <div
              className="hover-scroll-y mx-4"
              id="kt_aside_menu_wrapper"
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-height="auto"
              data-kt-scroll-wrappers="#kt_aside_menu"
              data-kt-scroll-offset="20px"
              data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
            >
              <div className="menu-item pt-5">
                <div className="menu-content">
                  <span className="fw-bold text-muted text-uppercase fs-7">
                    {t('campaigns.campaigns-title')}
                  </span>
                </div>
              </div>
              <HasAccessView view={[PROFILES.ROOT, PROFILES.ADMIN, PROFILES.STANDARD_USER]}>
                <div className="menu-item">
                  <Link
                    className={
                      `menu-link ` +
                      (location.pathname === '/sendCampaigns' || location.pathname === '/'
                        ? 'active'
                        : '')
                    }
                    to="/sendCampaigns"
                  >
                    <span className="menu-icon">
                      <span className="menu-icon">
                        <i className="ki-duotone ki-send fs-2">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                      </span>
                    </span>
                    <span className="menu-title">{t('campaigns.send-campaigns')}</span>
                  </Link>
                </div>
              </HasAccessView>
              <HasAccessView view={[PROFILES.ROOT]}>
                <div className="menu-item">
                  <Link
                    className={
                      `menu-link pulse pulse-primary ` +
                      (location.pathname === '/campaigns/monitoring' ||
                      location.pathname === '/campaigns/live-monitoring'
                        ? 'active'
                        : '')
                    }
                    to="/campaigns/monitoring"
                  >
                    <span className="menu-icon">
                      <span className="menu-icon">
                        <i className="ki-duotone ki-abstract-27 fs-1">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                        <span className="pulse-ring border-4"></span>
                      </span>
                    </span>
                    <span className="menu-title">{t('asaid.link-monitoring')}</span>
                  </Link>
                </div>
              </HasAccessView>
              <div className="menu-item pt-5">
                <div className="menu-content">
                  <span className="fw-bold text-muted text-uppercase fs-7">
                    {t('asaid.link-users')}
                  </span>
                </div>
              </div>
              <HasAccessView view={[PROFILES.ROOT, PROFILES.ADMIN, PROFILES.STANDARD_USER]}>
                <div className="menu-item">
                  <Link
                    className={`menu-link ` + (location.pathname === '/users' ? 'active' : '')}
                    to="/users"
                  >
                    <span className="menu-icon">
                      <span className="menu-icon">
                        <i className="ki-duotone ki-profile-user fs-2">
                          <span className="path1"></span>
                          <span className="path2"></span>
                          <span className="path3"></span>
                          <span className="path4"></span>
                        </i>
                      </span>
                    </span>
                    <span className="menu-title">{t('asaid.link-users')}</span>
                  </Link>
                </div>
              </HasAccessView>
              <HasAccessView view={[PROFILES.ROOT, PROFILES.ADMIN, PROFILES.STANDARD_USER]}>
                <div className="menu-item">
                  <Link
                    className={`menu-link ` + (location.pathname === '/roles' ? 'active' : '')}
                    to="/roles"
                  >
                    <span className="menu-icon">
                      <i className="ki-duotone ki-colors-square fs-1">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                      </i>
                    </span>
                    <span className="menu-title">{t('asaid.link-roles')}</span>
                  </Link>
                </div>
              </HasAccessView>
              <div className="menu-item pt-5">
                <div className="menu-content">
                  <span className="fw-bold text-muted text-uppercase fs-7">
                    {t('tools.tools-title')}
                  </span>
                </div>
              </div>
              <HasAccessView view={[PROFILES.ROOT, PROFILES.ADMIN, PROFILES.STANDARD_USER]}>
                <div className="menu-item">
                  <Link
                    className={`menu-link ` + (location.pathname === '/blacklist' ? 'active' : '')}
                    to="/blacklist"
                  >
                    <span className="menu-icon">
                      <i className="ki-duotone ki-shield fs-1">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                    </span>
                    <span className="menu-title">{t('asaid.link-blacklist')}</span>
                  </Link>
                </div>
              </HasAccessView>
              <div className="menu-item pt-5">
                <div className="menu-content">
                  <span className="fw-bold text-muted text-uppercase fs-7">
                    {t('asaid.link-title-operations')}
                  </span>
                </div>
              </div>
              <HasAccessView view={[PROFILES.ROOT]}>
                <div className="menu-item">
                  <Link
                    className={`menu-link ` + (location.pathname === '/companies' ? 'active' : '')}
                    to="/companies"
                  >
                    <span className="menu-icon">
                      <i className="ki-duotone ki-briefcase fs-2">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                        <span className="path5"></span>
                        <span className="path6"></span>
                      </i>
                    </span>
                    <span className="menu-title">{t('asaid.link-companies')}</span>
                  </Link>
                </div>
              </HasAccessView>
              <HasAccessView view={[PROFILES.ROOT, PROFILES.ADMIN, PROFILES.STANDARD_USER]}>
                <div className="menu-item">
                  <Link
                    className={`menu-link ` + (location.pathname === '/areas' ? 'active' : '')}
                    to="/areas"
                  >
                    <span className="menu-icon">
                      <i className="ki-duotone ki-data fs-2">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                        <span className="path5"></span>
                        <span className="path6"></span>
                      </i>
                    </span>
                    <span className="menu-title">{t('asaid.link-areas')}</span>
                  </Link>
                </div>
              </HasAccessView>
              <div
                data-kt-menu-trigger="click"
                className={
                  `menu-item menu-accordion mb-5 ` +
                  (location.pathname === '/channels/wa' ||
                  location.pathname === '/templates/wa' ||
                  location.pathname === '/templates/create-template-WA' ||
                  location.pathname === '/channels/rcs-basic' ||
                  location.pathname === '/channels/rcs-single' ||
                  location.pathname === '/channels/rcs-conversational' ||
                  location.pathname === '/templates/rcs' ||
                  location.pathname === '/templates/create-template-rcs' ||
                  location.pathname === '/channels/sms' ||
                  location.pathname === '/templates/sms' ||
                  location.pathname === '/templates/create-template-RCS' ||
                  location.pathname === '/channels/email' ||
                  location.pathname === '/channels/templates/email' ||
                  location.pathname === '/channels/templates/create-template-email' ||
                  location.pathname === '/channels/voz' ||
                  location.pathname === '/channels/telegram' ||
                  location.pathname === '/channels/messenger'
                    ? 'hover show'
                    : '')
                }
              >
                <span
                  className={`menu-link mb-2 ${location.pathname.includes('/channels') || location.pathname.includes('/templates') ? 'active' : ''}`}
                >
                  <span className="menu-icon">
                    <i className="ki-duotone ki-technology-4 fs-2">
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path3"></span>
                      <span className="path4"></span>
                      <span className="path5"></span>
                      <span className="path6"></span>
                      <span className="path7"></span>
                    </i>
                  </span>
                  <span className="menu-title">{t('asaid.link-menu-channels')}</span>
                  <span className="menu-arrow"></span>
                </span>
                <div className="menu-sub menu-sub-accordion">
                  {/* WA */}
                  <div className="menu-item">
                    <Link
                      className={
                        `menu-link ` +
                        (location.pathname === '/channels/wa' ||
                        location.pathname === '/templates/wa' ||
                        location.pathname === '/templates/create-template-WA'
                          ? 'active'
                          : '')
                      }
                      to="/channels/wa"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">WhatsApp</span>
                    </Link>
                  </div>
                  {/* RCS */}
                  <>
                    <div
                      className="menu menu-rounded menu-column menu-title-gray-700 menu-icon-gray-500 menu-arrow-gray-500 menu-bullet-gray-500 menu-arrow-gray-500 menu-state-bg fw-semibold w-250px"
                      data-kt-menu="true"
                    >
                      <div
                        className={`menu-item menu-sub-indention menu-accordion ${location.pathname.includes('/channels/rcs') || location.pathname.includes('/templates/rcs') ? 'hover show' : ''}`}
                        data-kt-menu-trigger="click"
                      >
                        <div
                          className={`menu-link py-3 ${location.pathname.includes('/channels/rcs') || location.pathname.includes('/templates/rcs') ? 'active' : ''}`}
                        >
                          <span className="menu-bullet">
                            <span className="bullet bullet-dot" />
                          </span>
                          <span className="menu-title">RCS</span>
                          <span className="menu-arrow" />
                        </div>
                        <div className="menu-sub menu-sub-accordion pt-3">
                          {/* BASIC */}
                          <div className="menu-item">
                            <Link
                              className={
                                `menu-link ` +
                                (location.pathname === '/channels/rcs-basic' ||
                                location.pathname === '/templates/create-template-rcs'
                                  ? 'active'
                                  : '')
                              }
                              to="/channels/rcs-basic"
                            >
                              <span className="menu-bullet">
                                <span className="bullet bullet-dot"></span>
                              </span>
                              <span className="menu-title">Basic</span>
                            </Link>
                          </div>
                          {/* SINGLE */}
                          <div className="menu-item">
                            <Link
                              className={
                                `menu-link ` +
                                (location.pathname === '/channels/rcs-single' ||
                                location.pathname === '/templates/create-template-rcs'
                                  ? 'active'
                                  : '')
                              }
                              to="/channels/rcs-single"
                            >
                              <span className="menu-bullet">
                                <span className="bullet bullet-dot"></span>
                              </span>
                              <span className="menu-title">Single</span>
                            </Link>
                          </div>
                          {/* CONVERSATIONAL */}
                          <div className="menu-item">
                            <Link
                              className={
                                `menu-link ` +
                                (location.pathname === '/channels/rcs-conversational' ||
                                location.pathname === '/templates/create-template-rcs'
                                  ? 'active'
                                  : '')
                              }
                              to="/channels/rcs-conversational"
                            >
                              <span className="menu-bullet">
                                <span className="bullet bullet-dot"></span>
                              </span>
                              <span className="menu-title">Conversational</span>
                            </Link>
                          </div>
                          {/* Templates */}
                          {/* <div className="menu-item">
                            <Link
                              className={
                                `menu-link ` +
                                (location.pathname === '/templates/rcs' ||
                                location.pathname === '/templates/create-template-rcs'
                                  ? 'active'
                                  : '')
                              }
                              to="/templates/rcs"
                            >
                              <span className="menu-bullet">
                                <span className="bullet bullet-dot"></span>
                              </span>
                              <span className="menu-title">Templates</span>
                            </Link>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </>
                  {/* SMS */}
                  <div className="menu-item">
                    <Link
                      className={
                        `menu-link ` +
                        (location.pathname === '/channels/sms' ||
                        location.pathname === '/templates/create-template-sms'
                          ? 'active'
                          : '')
                      }
                      to="/channels/sms"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">SMS</span>
                    </Link>
                  </div>
                  {/* EMAIL */}
                  <div className="menu-item">
                    <Link
                      className={
                        `menu-link ` +
                        (location.pathname === '/channels/email' ||
                        location.pathname === '/templates/create-template-email'
                          ? 'active'
                          : '')
                      }
                      to="/channels/email"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Email</span>
                    </Link>
                  </div>
                  {/* VOZ */}
                  <div className="menu-item">
                    <Link
                      className={
                        `menu-link ` + (location.pathname === '/channels/voz' ? 'active' : '')
                      }
                      to="/channels/voz"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Voz</span>
                    </Link>
                  </div>
                  {/* TELEGRAM */}
                  <div className="menu-item">
                    <Link
                      className={
                        `menu-link ` + (location.pathname === '/channels/telegram' ? 'active' : '')
                      }
                      to="/channels/telegram"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Telegram</span>
                    </Link>
                  </div>
                  {/* MESSENGER */}
                  <div className="menu-item">
                    <Link
                      className={
                        `menu-link ` + (location.pathname === '/channels/messenger' ? 'active' : '')
                      }
                      to="/channels/messenger"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Messenger</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Aside;
