import { LocalStorageService } from './services/local-storage.service';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { PROFILES, STORAGE } from './constants';
import ReactDOM from 'react-dom/client';
import AuthPage from './pages/auth/login.page';
import DashboardPage from './pages/dashboard/dashboard.page';
import TemplatesRCSPage from './pages/templates/rcs/templates-rcs.page';
import CreateTemplates from './pages/templates/rcs/create-templates.page';
import TemplatesWaPage from './pages/templates/wa/templates-wa.page';
import AreasPage from './pages/areas/areas.page';
import CompaniesPage from './pages/companies/companies.page';
import ChannelWaPage from './pages/channels/wa/channels-wa.page';
import ChannelSMSPage from './pages/channels/sms/channels-sms-page';
import NotFoundPage from './pages/errors/not-found.page';
import './index.scss';
import './i18/i18';
import { Toaster } from 'react-hot-toast';
import useBgPages from './hooks/bg-pages.hook';
import UsersPage from './pages/users/users.page';
import CampaignsPage from './pages/campaings/campaigns.page';
import RolesPage from './pages/roles/roles.page';
import BlackListPage from './pages/blacklist/blacklist.page';
import MonitoringPage from './pages/campaings/monitoring.page';
import LiveMonitoringPage from './pages/campaings/live-monitoring.page';
import HasAccessRoute from './guard/has-access-route';
import CreateTemplatesWa from './pages/templates/wa/create-wa.component';
import ChannelsEmailPage from './pages/channels/email/channels-email.page';
import ChannelsVozPage from './pages/channels/voz/channels-voz.page';
import ChannelsTelegramPage from './pages/channels/telegram/channels-telegram.page';
import ChannelsMessengerPage from './pages/channels/messenger/channels-voz.page';
import ChannelsRCSBasicPage from './pages/channels/rcs-basic/channels-rcs-basic.page';
import ChannelsRCSSinglePage from './pages/channels/rcs-single/channels-rcs-single.page';
import ChannelsRCSConversationalPage from './pages/channels/rcs-conversational/channels-rcs-conversational.page';

const PrivateRoute = () => {
  const session = LocalStorageService.get(STORAGE.SESSION);
  const token = LocalStorageService.get(STORAGE.TOKEN);

  if (!session || !token) {
    return <Navigate to="/auth/login" replace />;
  }

  return <Outlet />;
};

const LayoutRoute = () => {
  useBgPages();

  return (
    <>
      <Outlet />
    </>
  );
};

const Layout = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/auth">
            <Route path="login" element={<AuthPage />} />
          </Route>
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<LayoutRoute />}>
              <Route path="/" element={<CampaignsPage />} />
              <Route path="/dashboard" element={<DashboardPage />} />
              <Route
                path="/campaigns/monitoring"
                element={<HasAccessRoute view={[PROFILES.ROOT]} />}
              >
                <Route path="" element={<MonitoringPage />} />
              </Route>
              <Route
                path="/campaigns/live-monitoring"
                element={<HasAccessRoute view={[PROFILES.ROOT]} />}
              >
                <Route path="" element={<LiveMonitoringPage />} />
              </Route>
              <Route
                path="/users"
                element={
                  <HasAccessRoute view={[PROFILES.ROOT, PROFILES.ADMIN, PROFILES.STANDARD_USER]} />
                }
              >
                <Route path="" element={<UsersPage />} />
              </Route>
              <Route path="/templates/rcs" element={<TemplatesRCSPage />} />
              <Route path="/templates/create-template-RCS" element={<CreateTemplates />} />
              <Route path="/templates/wa" element={<TemplatesWaPage />} />
              <Route path="/templates/create-template-WA" element={<CreateTemplatesWa />} />
              <Route
                path="/areas"
                element={
                  <HasAccessRoute view={[PROFILES.ROOT, PROFILES.ADMIN, PROFILES.STANDARD_USER]} />
                }
              >
                <Route path="" element={<AreasPage />} />
              </Route>

              <Route path="/companies" element={<HasAccessRoute view={[PROFILES.ROOT]} />}>
                <Route path="" element={<CompaniesPage />} />
              </Route>
              <Route path="/channels/wa" element={<ChannelWaPage />} />
              <Route path="/channels/sms" element={<ChannelSMSPage />} />
              <Route path="/channels/email" element={<ChannelsEmailPage />} />
              <Route path="/channels/voz" element={<ChannelsVozPage />} />
              <Route path="/channels/telegram" element={<ChannelsTelegramPage />} />
              <Route path="/channels/messenger" element={<ChannelsMessengerPage />} />
              <Route path="/channels/rcs-basic" element={<ChannelsRCSBasicPage />} />
              <Route path="/channels/rcs-single" element={<ChannelsRCSSinglePage />} />
              <Route
                path="/channels/rcs-conversational"
                element={<ChannelsRCSConversationalPage />}
              />
              <Route
                path="/sendCampaigns"
                element={
                  <HasAccessRoute view={[PROFILES.ROOT, PROFILES.ADMIN, PROFILES.STANDARD_USER]} />
                }
              >
                <Route path="" element={<CampaignsPage />} />
              </Route>
              <Route path="/roles" element={<RolesPage />} />
              <Route
                path="/roles"
                element={<HasAccessRoute view={[PROFILES.ROOT, PROFILES.ADMIN]} />}
              >
                <Route path="" element={<RolesPage />} />
              </Route>
              <Route
                path="/blacklist"
                element={
                  <HasAccessRoute view={[PROFILES.ROOT, PROFILES.ADMIN, PROFILES.STANDARD_USER]} />
                }
              >
                <Route path="" element={<BlackListPage />} />
              </Route>
            </Route>
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <Toaster position="top-center" containerStyle={{ zIndex: 1 }} />
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<Layout />);
