import { useEffect, useRef, useState } from 'react';
import useModal from '../../hooks/close-modal.hook';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Input from '../../components/forms/input.component';
import Select from 'react-select';
import Button from '../../components/forms/button.component';
import Title from '../../components/forms/titles.component';
import InvalidFeedback from '../../components/forms/invalid-feedback.component';
import { PROFILES } from '../../constants';
import { CompaniesService } from '../../services/companies.service';
import { Areas } from '../../services/area.service';
import { RolesService } from '../../services/roles.service';
import { AlertService } from '../../services/alert.service';

interface AddRolesProps {
  show: boolean;
  onRequestClose: (refresh: boolean) => void;
}

function AddRoles({ show, onRequestClose }: AddRolesProps) {
  const { t } = useTranslation();
  const modalRef = useRef<HTMLDivElement>(null);
  useModal(modalRef, show);
  const [companies, setCompanies] = useState<any>();
  const [isGlobal, setIsGlobal] = useState<boolean>(false);
  const [areas, setAreas] = useState<any>([]);
  const defaultValues = {
    _id: '',
    name: '',
    is_global: false,
    profile: '',
    company: '',
    company_id: '',
    permissions_blocked: {
      companies: {
        read: false,
        write: false
      },
      areas: {
        read: false,
        write: false
      },
      roles: {
        read: false,
        write: false
      },
      users: {
        read: false,
        write: false
      }
    },
    areas: []
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isValid, isSubmitting }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const companiesRead = watch('permissions_blocked.companies.read');
  const areasRead = watch('permissions_blocked.areas.read');
  const rolesRead = watch('permissions_blocked.roles.read');
  const usersRead = watch('permissions_blocked.users.read');

  const options = [
    { value: PROFILES.ROOT, label: t('roles.rol-root') },
    { value: PROFILES.ADMIN, label: t('roles.rol-admin') },
    { value: PROFILES.STANDARD_USER, label: t('roles.rol-standard-user') },
    { value: PROFILES.AGENTE, label: t('roles.rol-agent') }
  ];

  const getCompanies = async () => {
    const data = await CompaniesService.getCompanies();
    if (data) {
      const mappedCompanies = data?.map((item: any) => ({
        value: item._id,
        label: item.name
      }));
      setCompanies(mappedCompanies);
    } else {
      setCompanies([]);
    }
  };

  const getAreas = async () => {
    if (
      (watch('company_id') && watch('profile') === PROFILES.AGENTE) ||
      watch('profile') === PROFILES.STANDARD_USER ||
      watch('profile') === PROFILES.ADMIN
    ) {
      const data = await Areas.getAreas({ company: watch('company_id') });
      const mappedAreas = data.map((item: any) => ({
        value: item._id,
        label: item.name
      }));
      setAreas(mappedAreas);
    } else {
      setAreas([]);
    }
  };

  const submit = async (data: any) => {
    try {
      data = RequestBody(data);
      await RolesService.saveRoles(data);
      reset(defaultValues);
      setIsGlobal(false);
      onRequestClose(true);
      AlertService.toastSuccess('¡Se ha agregado el rol correctamente!');
    } catch (error) {
      AlertService.toastError('¡Ha pasado algo! Vuelve a intentarlo de nuevo');
      console.log(error);
    }
  };

  const RequestBody = (data: any) => {
    delete data._id;
    delete data.company_id;
    if (data.profile === PROFILES.ROOT) {
      delete data.company;
      delete data.areas;
    }
    if (data.profile === PROFILES.ROOT) {
      delete data.is_global;
    }
    if (data.profile === PROFILES.ADMIN) {
      delete data.areas;
    }
    if (!isGlobal) {
      data.areas = [data.areas];
    }
    return data;
  };

  const closeModal = () => {
    onRequestClose(false);
    reset(defaultValues);
    setIsGlobal(false);
  };

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {
    getAreas();
  }, [watch('company_id')]);

  useEffect(() => {
    setValue('is_global', isGlobal);
  }, [isGlobal, setValue]);

  useEffect(() => {
    if (!companiesRead) setValue('permissions_blocked.companies.write', false);
    if (!areasRead) setValue('permissions_blocked.areas.write', false);
    if (!rolesRead) setValue('permissions_blocked.roles.write', false);
    if (!usersRead) setValue('permissions_blocked.users.write', false);
  }, [companiesRead, areasRead, rolesRead, usersRead]);

  return (
    <div aria-hidden="true" className="modal fade" ref={modalRef} data-bs-backdrop="static">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header justify-content-end border-0 pb-0">
            <div
              className="btn btn-sm btn-icon btn-active-light-primary"
              onClick={() => closeModal()}
            >
              <i className="ki-duotone ki-cross fs-2x">
                <span className="path1" />
                <span className="path2" />
              </i>
            </div>
          </div>
          <form onSubmit={handleSubmit(submit)}>
            <div className="modal-body pt-0 pb-15 px-5 px-xl-20">
              <div className="mt-1 text-center">
                <h1>{t('roles.add-rol-title')}</h1>
                <div className="text-muted fw-semibold fs-5">
                  <span
                    dangerouslySetInnerHTML={{ __html: t('channels.sms.modal-C-Subtitle') }}
                  ></span>
                </div>
                <div className="separator border-2 my-5"></div>
              </div>
              <div className="row mt-10">
                <div className="col-lg-6 mb-10 mb-lg-0 h-100 p-10">
                  <Title
                    icon={'ki-notepad-edit'}
                    title={t('roles.title-data-roles')}
                    subtitle={t('roles.text-data-roles')}
                  />
                  <div>
                    <div className="row">
                      <div className="col">
                        <div className="fv-row mb-7">
                          <Input
                            label={t('roles.name-label')}
                            requiredIndicator="required"
                            placeholder={t('roles.name-placeholder')}
                            errors={errors.name}
                            {...register('name', {
                              required: true,
                              setValueAs: (value) => value.trim()
                            })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="fv-row mb-7">
                          <label className="form-label required">{t('roles.profile-label')}</label>
                          <Controller
                            control={control}
                            name="profile"
                            rules={{ required: true }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <>
                                <Select
                                  options={options}
                                  noOptionsMessage={() => (
                                    <div className="empty-select">{t('general.no-options')}</div>
                                  )}
                                  className={
                                    error ? 'form-select-custom is-invalid' : 'form-select-custom'
                                  }
                                  classNamePrefix="form-select-custom"
                                  placeholder={t('roles.profile-placeholder')}
                                  onChange={(selectedOption) => {
                                    onChange(selectedOption ? selectedOption.value : null);
                                    setValue('profile', selectedOption ? selectedOption.value : '');
                                  }}
                                  value={options.find((option) => option.value === value) || null}
                                />
                                <InvalidFeedback error={error} />
                              </>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    {(watch('profile') === PROFILES.ADMIN ||
                      watch('profile') === PROFILES.STANDARD_USER ||
                      watch('profile') === PROFILES.AGENTE) && (
                      <div className="row">
                        <div className="col">
                          <div className="fv-row mb-7">
                            <label className="form-label required">
                              {t('roles.company-label')}
                            </label>
                            <Controller
                              control={control}
                              name="company"
                              rules={{ required: true }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <>
                                  <Select
                                    options={companies}
                                    noOptionsMessage={() => (
                                      <div className="empty-select">{t('general.no-options')}</div>
                                    )}
                                    className={
                                      error ? 'form-select-custom is-invalid' : 'form-select-custom'
                                    }
                                    classNamePrefix="form-select-custom"
                                    placeholder={t('roles.company-placeholder')}
                                    onChange={(selectedOption) => {
                                      onChange(selectedOption ? selectedOption.value : null);
                                      setValue(
                                        'company_id',
                                        selectedOption ? selectedOption.value : ''
                                      );
                                    }}
                                    value={companies?.find(
                                      (company: any) => company.value === value
                                    )}
                                  />
                                  <InvalidFeedback error={error} />
                                </>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {(watch('profile') === PROFILES.STANDARD_USER ||
                      watch('profile') === PROFILES.AGENTE ||
                      watch('profile') === PROFILES.ADMIN) && (
                      <div className="row">
                        <div className="col">
                          <div className="fv-row mb-7">
                            <div className="form-check form-switch form-check-custom form-check-solid">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexSwitchDefault"
                                checked={isGlobal}
                                onChange={(e) => {
                                  setIsGlobal(e.target.checked);
                                  setValue('is_global', e.target.checked);
                                }}
                              />
                              <label className="form-check-label" htmlFor="flexSwitchDefault">
                                {t('roles.is-global')}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {(watch('profile') === PROFILES.STANDARD_USER ||
                      watch('profile') === PROFILES.AGENTE) && (
                      <div className="row">
                        <div className="col">
                          <div className="fv-row mb-7">
                            <label className={`form-label required`}>
                              {t('roles.areas-label')}
                            </label>
                            <Controller
                              control={control}
                              name="areas"
                              rules={{ required: true }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <>
                                  <Select
                                    options={areas}
                                    isMulti={isGlobal}
                                    isDisabled={watch('company_id') === ''}
                                    noOptionsMessage={() => (
                                      <div className="empty-select">{t('general.no-options')}</div>
                                    )}
                                    className={
                                      error ? 'form-select-custom is-invalid' : 'form-select-custom'
                                    }
                                    classNamePrefix="form-select-custom"
                                    placeholder={t('roles.areas-placeholder')}
                                    onChange={(selectedOptions) => {
                                      if (isGlobal) {
                                        onChange(
                                          selectedOptions
                                            ? selectedOptions.map((option: any) => option.value)
                                            : []
                                        );
                                      } else {
                                        onChange(selectedOptions ? selectedOptions.value : null);
                                      }
                                    }}
                                    value={
                                      isGlobal
                                        ? areas.filter((area: any) =>
                                            ((value as string[]) || []).includes(area.value)
                                          )
                                        : areas.find((area: any) => area.value === value) || null
                                    }
                                  />
                                  <InvalidFeedback error={error} />
                                </>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* PERMISOS */}
                <div className="col-lg-6 rounded h-100 p-10">
                  <div className="accordion accordion-icon-toggle" id="kt_accordion_2">
                    <div>
                      <div
                        className="accordion-header py-3 d-flex"
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_accordion_2_item_1"
                      >
                        <label className="d-flex flex-stack cursor-pointer mb-5">
                          <span className="d-flex align-items-center me-2">
                            <span className="symbol symbol-circle symbol-50px me-6">
                              <span
                                className={`symbol-label ${companiesRead ? 'bg-light-danger' : ''}`}
                              >
                                <i
                                  className={`ki-duotone ki-briefcase fs-2x ${companiesRead ? 'text-danger' : ''}`}
                                >
                                  <span className="path1"></span>
                                  <span className="path2"></span>
                                  <span className="path3"></span>
                                  <span className="path4"></span>
                                  <span className="path5"></span>
                                  <span className="path6"></span>
                                </i>
                              </span>
                            </span>
                            <span className="d-flex flex-column">
                              <span className="fw-bold fs-4">
                                {t('asaid.link-companies')}
                                <span className="fs-8 text-muted ms-1">
                                  ({t('general.optional')})
                                </span>
                                <i
                                  className={`ki-duotone ki-eye ms-4 fs-2x ${watch('permissions_blocked.companies.read') ? 'text-primary' : ''}`}
                                >
                                  <span className="path1"></span>
                                  <span className="path2"></span>
                                  <span className="path3"></span>
                                </i>
                                <i
                                  className={`ki-duotone ki-message-edit ms-2 fs-2x ${watch('permissions_blocked.companies.write') ? 'text-primary' : ''}`}
                                >
                                  <span className="path1"></span>
                                  <span className="path2"></span>
                                </i>
                              </span>
                            </span>
                          </span>
                        </label>
                        <span className="accordion-icon ms-auto">
                          <i className="ki-duotone ki-arrow-right fs-4">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                        </span>
                      </div>
                      <div
                        id="kt_accordion_2_item_1"
                        className="fs-6 collapse show ps-10"
                        data-bs-parent="#kt_accordion_2"
                      >
                        <div className="ms-8">
                          <div className="fv-row mb-5 justify-content-center">
                            <div className="form-check mb-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckCompaniesRead"
                                {...register('permissions_blocked.companies.read', {
                                  required: false
                                })}
                              />
                              <label className="form-check-label" htmlFor="flexCheckCompaniesRead">
                                {t('roles.read')}
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckCompaniesWrite"
                                disabled={!companiesRead}
                                {...register('permissions_blocked.companies.write', {
                                  required: false
                                })}
                              />
                              <label className="form-check-label" htmlFor="flexCheckCompaniesWrite">
                                {t('roles.write')}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div
                        className="accordion-header py-3 d-flex collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_accordion_2_item_2"
                      >
                        <label className="d-flex flex-stack cursor-pointer mb-5">
                          <span className="d-flex align-items-center me-2">
                            <span className="symbol symbol-circle symbol-50px me-6">
                              <span
                                className={`symbol-label ${areasRead ? 'bg-light-danger' : ''}`}
                              >
                                <i
                                  className={`ki-duotone ki-data fs-2x ${areasRead ? 'text-danger' : ''}`}
                                >
                                  <span className="path1"></span>
                                  <span className="path2"></span>
                                  <span className="path3"></span>
                                  <span className="path4"></span>
                                  <span className="path5"></span>
                                  <span className="path6"></span>
                                </i>
                              </span>
                            </span>
                            <span className="d-flex flex-column">
                              <span className="fw-bold fs-4">
                                {t('asaid.link-areas')}
                                <span className="fs-8 text-muted ms-1">
                                  ({t('general.optional')})
                                </span>
                                <i
                                  className={`ki-duotone ki-eye ms-4 fs-2x ${watch('permissions_blocked.areas.read') ? 'text-primary' : ''}`}
                                >
                                  <span className="path1"></span>
                                  <span className="path2"></span>
                                  <span className="path3"></span>
                                </i>
                                <i
                                  className={`ki-duotone ki-message-edit ms-2 fs-2x ${watch('permissions_blocked.areas.write') ? 'text-primary' : ''}`}
                                >
                                  <span className="path1"></span>
                                  <span className="path2"></span>
                                </i>
                              </span>
                            </span>
                          </span>
                        </label>
                        <span className="accordion-icon ms-auto">
                          <i className="ki-duotone ki-arrow-right fs-4">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                        </span>
                      </div>
                      <div
                        id="kt_accordion_2_item_2"
                        className="collapse fs-6 ps-10"
                        data-bs-parent="#kt_accordion_2"
                      >
                        <div className="ms-8">
                          <div className="fv-row mb-5 justify-content-center">
                            <div className="form-check mb-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckCompaniesRead"
                                {...register('permissions_blocked.areas.read', {
                                  required: false
                                })}
                              />
                              <label className="form-check-label" htmlFor="flexCheckCompaniesRead">
                                {t('roles.read')}
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckCompaniesWrite"
                                disabled={!areasRead}
                                {...register('permissions_blocked.areas.write', {
                                  required: false
                                })}
                              />
                              <label className="form-check-label" htmlFor="flexCheckCompaniesWrite">
                                {t('roles.write')}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div
                        className="accordion-header py-3 d-flex collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_accordion_2_item_3"
                      >
                        <label className="d-flex flex-stack cursor-pointer mb-5">
                          <span className="d-flex align-items-center me-2">
                            <span className="symbol symbol-circle symbol-50px me-6">
                              <span
                                className={`symbol-label ${rolesRead ? 'bg-light-danger' : ''}`}
                              >
                                <i
                                  className={`ki-duotone ki-colors-square fs-2x ${rolesRead ? 'text-danger' : ''}`}
                                >
                                  <span className="path1"></span>
                                  <span className="path2"></span>
                                  <span className="path3"></span>
                                  <span className="path4"></span>
                                </i>
                              </span>
                            </span>
                            <span className="d-flex flex-column">
                              <span className="fw-bold fs-4">
                                {t('asaid.link-roles')}
                                <span className="fs-8 text-muted ms-1">
                                  ({t('general.optional')})
                                </span>
                                <i
                                  className={`ki-duotone ki-eye ms-4 fs-2x ${watch('permissions_blocked.roles.read') ? 'text-primary' : ''}`}
                                >
                                  <span className="path1"></span>
                                  <span className="path2"></span>
                                  <span className="path3"></span>
                                </i>
                                <i
                                  className={`ki-duotone ki-message-edit ms-2 fs-2x ${watch('permissions_blocked.roles.write') ? 'text-primary' : ''}`}
                                >
                                  <span className="path1"></span>
                                  <span className="path2"></span>
                                </i>
                              </span>
                            </span>
                          </span>
                        </label>
                        <span className="accordion-icon ms-auto">
                          <i className="ki-duotone ki-arrow-right fs-4">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                        </span>
                      </div>
                      <div
                        id="kt_accordion_2_item_3"
                        className="collapse fs-6 ps-10"
                        data-bs-parent="#kt_accordion_2"
                      >
                        <div className="ms-8">
                          <div className="fv-row mb-5 justify-content-center">
                            <div className="form-check mb-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckRolesRead"
                                {...register('permissions_blocked.roles.read', {
                                  required: false
                                })}
                              />
                              <label className="form-check-label" htmlFor="flexCheckRolesRead">
                                {t('roles.read')}
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckRolesWrite"
                                disabled={!rolesRead}
                                {...register('permissions_blocked.roles.write', {
                                  required: false
                                })}
                              />
                              <label className="form-check-label" htmlFor="flexCheckRolesWrite">
                                {t('roles.write')}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div
                        className="accordion-header py-3 d-flex collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_accordion_2_item_4"
                      >
                        <label className="d-flex flex-stack cursor-pointer mb-5">
                          <span className="d-flex align-items-center me-2">
                            <span className="symbol symbol-circle symbol-50px me-6">
                              <span
                                className={`symbol-label ${usersRead ? 'bg-light-danger' : ''}`}
                              >
                                <i
                                  className={`ki-duotone ki-profile-user fs-2x ${usersRead ? 'text-danger' : ''}`}
                                >
                                  <span className="path1"></span>
                                  <span className="path2"></span>
                                  <span className="path3"></span>
                                  <span className="path4"></span>
                                </i>
                              </span>
                            </span>
                            <span className="d-flex flex-column">
                              <span className="fw-bold fs-4">
                                {t('asaid.link-users')}
                                <span className="fs-8 text-muted ms-1">
                                  ({t('general.optional')})
                                </span>
                                <i
                                  className={`ki-duotone ki-eye ms-4 fs-2x ${watch('permissions_blocked.users.read') ? 'text-primary' : ''}`}
                                >
                                  <span className="path1"></span>
                                  <span className="path2"></span>
                                  <span className="path3"></span>
                                </i>
                                <i
                                  className={`ki-duotone ki-message-edit ms-2 fs-2x ${watch('permissions_blocked.users.write') ? 'text-primary' : ''}`}
                                >
                                  <span className="path1"></span>
                                  <span className="path2"></span>
                                </i>
                              </span>
                            </span>
                          </span>
                        </label>
                        <span className="accordion-icon ms-auto">
                          <i className="ki-duotone ki-arrow-right fs-4">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                        </span>
                      </div>
                      <div
                        id="kt_accordion_2_item_4"
                        className="collapse fs-6 ps-10"
                        data-bs-parent="#kt_accordion_2"
                      >
                        <div className="ms-8">
                          <div className="fv-row mb-5 justify-content-center">
                            <div className="form-check mb-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckUsersRead"
                                {...register('permissions_blocked.users.read', {
                                  required: false
                                })}
                              />
                              <label className="form-check-label" htmlFor="flexCheckUsersRead">
                                {t('roles.read')}
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckUsersWrite"
                                disabled={!usersRead}
                                {...register('permissions_blocked.users.write', {
                                  required: false
                                })}
                              />
                              <label className="form-check-label" htmlFor="flexCheckUsersWrite">
                                {t('roles.write')}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-center flex-row-fluid pt-12">
                <button
                  type="button"
                  className="btn btn-light hover-scale"
                  onClick={() => closeModal()}
                >
                  {t('general.close')}
                </button>
                <Button
                  type="submit"
                  id="sendData"
                  className="btn btn-primary ms-3 hover-scale"
                  disabled={!isValid}
                  isLoading={isSubmitting}
                >
                  {t('roles.btn-add-modal')}
                  <i className="ki-duotone ki-send ms-2 fs-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddRoles;
