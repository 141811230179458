import React from 'react';
import useHasAccess from '../hooks/use-has-access.hook';

interface HasAccessViewProps {
  children: any;
  view: string[];
}

const HasAccessView = ({ children, view }: HasAccessViewProps) => {
  const { isAllowed } = useHasAccess({ view });

  return isAllowed ? children : null;
};

export default HasAccessView;
