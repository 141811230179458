import React from 'react';

export default React.forwardRef(
  ({ type = 'primary', title = '', subtitle = '', icon = '', required = false }: any, ref) => {
    return (
      <>
        <label className="d-flex flex-stack mb-7">
          <span className="d-flex align-items-center me-2">
            <span className="symbol symbol-circle symbol-50px me-6">
              <span className={`symbol-label bg-light-${type}`}>
                <i className={`ki-duotone ${icon} fs-2x text-${type}`}>
                  <span className="path1" />
                  <span className="path2" />
                  <span className="path3" />
                  <span className="path4" />
                  <span className="path5" />
                  <span className="path6" />
                  <span className="path7" />
                  <span className="path8" />
                  <span className="path9" />
                  <span className="path10" />
                </i>
              </span>
            </span>
            <span className="d-flex flex-column">
              <h2 className="fw-bold">
                {title}
                {required && (
                  <>
                    {' '}
                    <span className="required"></span>
                  </>
                )}
              </h2>
              <div className="text-muted fw-semibold fs-6">{subtitle}</div>
            </span>
          </span>
        </label>
      </>
    );
  }
);
