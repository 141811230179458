import React from 'react';
import { useTranslation } from 'react-i18next';

export default React.forwardRef(
  (
    {
      type = 'primary',
      icon = '',
      title = '',
      subtitle = '',
      required = false,
      value = '',
      checked,
      onChange
    }: any,
    ref
  ) => {
    const { t } = useTranslation();
    return (
      <>
        <label className="d-flex flex-stack cursor-pointer mb-5">
          <span className="d-flex align-items-center me-2">
            <span className="symbol symbol-circle symbol-50px  me-6">
              <span className={`symbol-label bg-light-${type}`}>
                <i className={`ki-duotone ${icon} fs-2x text-${type}`}>
                  <span className="path1" />
                  <span className="path2" />
                  <span className="path3" />
                  <span className="path4" />
                  <span className="path5" />
                  <span className="path6" />
                  <span className="path7" />
                  <span className="path8" />
                  <span className="path9" />
                  <span className="path10" />
                </i>
              </span>
            </span>
            <span className="d-flex flex-column">
              <span className="fw-bold fs-6">
                {title}
                {required ? (
                  <label className="form-label required "></label>
                ) : (
                  <span className="fs-8 text-muted ms-1">({t('general.optional')})</span>
                )}
              </span>
              <span className="fs-7 text-muted">{subtitle}</span>
            </span>
          </span>
          <span className="form-check form-check-custom form-check-solid">
            <input
              className="form-check-input st-b"
              name="framework"
              checked={checked}
              type="checkbox"
              onChange={onChange}
              value={value}
            />
          </span>
        </label>
      </>
    );
  }
);
