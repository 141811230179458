import React from 'react';

export default React.forwardRef(({ title = '', subtitle = '' }: any, ref) => {
  return (
    <>
      <span className="d-flex flex-column mb-10">
        <span className="fw-bold fs-2">{title}</span>
        <span className="fs-7 text-muted">{subtitle}</span>
      </span>
    </>
  );
});
