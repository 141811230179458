import React, { useRef } from 'react';
import useModal from '../../../hooks/close-modal.hook';
import { Controller, useForm } from 'react-hook-form';
import { AlertService } from '../../../services/alert.service';
import { useTranslation } from 'react-i18next';
import Input from '../../../components/forms/input.component';
import Title from '../../../components/forms/titles.component';
import Button from '../../../components/forms/button.component';
import Select from 'react-select';
import InvalidFeedback from '../../../components/forms/invalid-feedback.component';

interface ModalProps {
  show: boolean;
  onRequestClose: (refresh: boolean) => void;
  payload: any;
}

function FormModal({ show, onRequestClose, payload }: ModalProps) {
  const { t } = useTranslation();
  const modalRef = useRef<HTMLDivElement>(null);
  useModal(modalRef, show);

  const providers = [
    {
      value: '1',
      label: 'Mailchimp'
    },
    {
      value: '2',
      label: 'SendGrid'
    }
  ];

  const defaultValues = {
    provider: '',
    host: '',
    port: '',
    user: '',
    password: '',
    secure: false,
    name_from: '',
    email_from: ''
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isValid, isSubmitting }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const resetForm = () => {
    onRequestClose(false);
    reset(defaultValues);
  };

  const submit = async () => {
    await AlertService.toastSuccess('Ok');
  };

  return (
    <div className="modal fade" ref={modalRef} data-bs-backdrop="static">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header justify-content-end border-0 pb-0">
            <div
              className="btn btn-sm btn-icon btn-active-light-primary"
              onClick={() => resetForm()}
            >
              <i className="ki-duotone ki-cross fs-2x">
                <span className="path1" />
                <span className="path2" />
              </i>
            </div>
          </div>
          <form onSubmit={handleSubmit(submit)}>
            <div className="modal-body pt-0 pb-15 px-5 px-xl-20">
              <div className="mt-1 text-center">
                <h1 className="mb-3">Registar nueva cuenta</h1>
                <div className="text-muted fw-semibold fs-5">
                  <span
                    dangerouslySetInnerHTML={{ __html: t('channels.sms.modal-C-Subtitle') }}
                  ></span>
                </div>
                <div className="separator border-2 my-5"></div>
              </div>
              <div className="row my-10 px-10">
                <div className="col-lg-6 mb-10 mb-lg-0  h-100 p-10">
                  <Title
                    title={t('channels.wa.modal-data')}
                    subtitle={t('channels.wa.modal-data-sub')}
                    icon="ki-notepad-edit"
                  />

                  <div className="fv-row mb-7">
                    <label className="form-label required">Proveedor</label>
                    <Controller
                      control={control}
                      name="provider"
                      rules={{ required: true }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <>
                          <Select
                            options={providers}
                            noOptionsMessage={() => (
                              <div className="empty-select">{t('general.no-options')}</div>
                            )}
                            className={
                              error ? 'form-select-custom is-invalid' : 'form-select-custom'
                            }
                            classNamePrefix="form-select-custom"
                            placeholder={t('channels.wa.placecholder-provider')}
                            onChange={(selectedOption: { value: string; label: string } | null) => {
                              onChange(selectedOption ? selectedOption.value : null);
                            }}
                            value={
                              providers.find(
                                (option: { value: string; label: string }) => option.value === value
                              ) || null
                            }
                          />
                          <InvalidFeedback error={error} />
                        </>
                      )}
                    />
                  </div>

                  <div className="fv-row mb-4">
                    <Input
                      label="Nombre de remitente"
                      requiredIndicator="required"
                      errors={errors.name_from}
                      {...register('name_from', {
                        required: true,
                        setValueAs: (value) => value.trim()
                      })}
                    />
                  </div>
                  <div className="fv-row mb-4">
                    <Input
                      label="Correo de remitente"
                      requiredIndicator="required"
                      errors={errors.email_from}
                      {...register('email_from', {
                        required: true,
                        setValueAs: (value) => value.trim()
                      })}
                    />
                  </div>
                </div>
                <div className="col-lg-6 mb-10 mb-lg-0  h-100 p-10">
                  <Title
                    icon={'ki-setting-2'}
                    type={'info'}
                    title={'Configuración técnica'}
                    subtitle={'Configura los parámetros técnicos necesarios'}
                  />
                  <div className="fv-row mb-4">
                    <Input
                      label="Host"
                      requiredIndicator="required"
                      errors={errors.host}
                      {...register('host', {
                        required: true,
                        setValueAs: (value) => value.trim()
                      })}
                    />
                  </div>
                  <div className="fv-row mb-4">
                    <Input
                      label="Puerto"
                      requiredIndicator="required"
                      errors={errors.port}
                      {...register('port', {
                        required: true,
                        setValueAs: (value) => value.trim()
                      })}
                    />
                  </div>
                  <div className="fv-row mb-4">
                    <Input
                      label="Usuario"
                      requiredIndicator="required"
                      errors={errors.user}
                      {...register('user', {
                        required: true,
                        setValueAs: (value) => value.trim()
                      })}
                    />
                  </div>
                  <div className="fv-row mb-4">
                    <Input
                      label="Contraseña"
                      requiredIndicator="required"
                      errors={errors.password}
                      {...register('password', {
                        required: true,
                        setValueAs: (value) => value.trim()
                      })}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-center flex-row-fluid pt-12">
                <button
                  type="button"
                  className="btn btn-light hover-scale"
                  onClick={() => resetForm()}
                >
                  Cerrar
                </button>
                <Button
                  type="submit"
                  id="sendData"
                  className="btn btn-primary ms-3 hover-scale"
                  disabled={!isValid}
                  isLoading={isSubmitting}
                >
                  Guardar
                  <i className="ki-duotone ki-send ms-2 fs-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default FormModal;
