import { useEffect, useRef, useState } from 'react';
import useModal from '../../hooks/close-modal.hook';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Input from '../../components/forms/input.component';
import Select from 'react-select';
import Button from '../../components/forms/button.component';
import Title from '../../components/forms/titles.component';
import InvalidFeedback from '../../components/forms/invalid-feedback.component';
import { PROFILES } from '../../constants';
import { RolesService } from '../../services/roles.service';
import { UsersService } from '../../services/users.service';
import { AlertService } from '../../services/alert.service';
import { CompaniesService } from '../../services/companies.service';
import { Areas } from '../../services/area.service';
import InputCopyClipboard from '../../components/forms/clipboard-copy.component';

interface AddUserProps {
  show: boolean;
  onRequestClose: (refresh: boolean) => void;
  payload: any;
}

function AddUser({ show, onRequestClose, payload }: AddUserProps) {
  const { t } = useTranslation();
  const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const modalRef = useRef<HTMLDivElement>(null);
  useModal(modalRef, show);
  const [roles, setRoles] = useState<any>([]);
  const [companies, setCompanies] = useState<any>([]);
  const [areas, setAreas] = useState<any>([]);
  const defaultValues = {
    _id: '',
    username: '',
    full_name: '',
    email: '',
    profile: '',
    role: '',
    company: '',
    areas: [],
    area: []
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    resetField,
    setValue,
    watch,
    formState: { errors, isValid, isSubmitting }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const resetValues = () => {
    setAreas([]);
    setCompanies([]);
    setRoles([]);
  };

  const options = [
    { value: PROFILES.ROOT, label: t('users-page.root-profile') },
    { value: PROFILES.ADMIN, label: t('users-page.admin-profile') },
    { value: PROFILES.STANDARD_USER, label: t('users-page.standard-user-profile') },
    { value: PROFILES.AGENTE, label: t('users-page.agent-profile') }
  ];

  const getRoles = async (profile: string, company: string | null) => {
    const data = await RolesService.getRoles();
    const mappedRoles = data
      .filter((item: any) => {
        if (company) {
          return item.profile === profile && item.company === company;
        }
        return item.profile === profile;
      })
      .map((item: any) => ({
        value: item._id,
        label: item.name
      }));
    setRoles(mappedRoles);
  };

  const getCompanies = async () => {
    const data = await CompaniesService.getCompanies();
    const mappedCompanies = data.map((item: any) => ({
      value: item._id,
      label: item.name
    }));
    setCompanies(mappedCompanies);
  };

  const getAreas = async () => {
    if (watch('company') != '') {
      const data = await Areas.getAreas({ company: watch('company') });
      const mappedAreas = data.map((item: any) => ({
        value: item._id,
        label: item.name
      }));
      setAreas(mappedAreas);
    } else {
      setAreas([]);
    }
  };

  const isMulti = () => {
    if (watch('profile') === PROFILES.STANDARD_USER) {
      return true;
    } else if (watch('profile') === PROFILES.AGENTE) {
      return false;
    }
  };
  const submit = async (data: any) => {
    let response;
    try {
      if (payload != null) {
        data = RequestBody(data);
        await UsersService.editUser(data);
        AlertService.toastSuccess(t('users-page.edit-user-success'));
      } else {
        delete data._id;
        data = RequestBody(data);
        response = await UsersService.saveUser(data);
        AlertService.toastSuccess(t('users-page.add-user-success'));
      }
      onRequestClose(true);
      const html = (
        <div>
          <p>{t('users-page.modal-add-user.password')}</p>
          <div className="width-fit-content m-auto">
            <InputCopyClipboard text={response?.password} />
          </div>
        </div>
      );
      await AlertService.success(
        <p className="h2">{t('users-page.modal-add-user.password-label-success')}</p>,
        html
      );
      reset(defaultValues);
      resetValues();
    } catch (error) {
      console.log(error);
      AlertService.toastError(t('users-page.user-error'));
    }
  };

  const closeModal = () => {
    onRequestClose(false);
    reset(defaultValues);
    resetValues();
  };

  const RequestBody = (data: any) => {
    if (watch('profile') === PROFILES.ROOT) {
      delete data.company;
    }
    if (watch('profile') === PROFILES.AGENTE) {
      delete data.areas;
    }
    if (watch('profile') === PROFILES.STANDARD_USER) {
      data.areas = data.areas;
      delete data.area;
    } else if (watch('profile') === PROFILES.AGENTE) {
      data.area = data.area;
      delete data.areas;
    }
    if (watch('profile') === PROFILES.ADMIN) {
      delete data.areas;
      delete data.area;
    }
    return data;
  };

  useEffect(() => {
    if (
      watch('profile') === PROFILES.ADMIN ||
      watch('profile') === PROFILES.AGENTE ||
      watch('profile') === PROFILES.STANDARD_USER
    ) {
      getCompanies();
      if (watch('profile')) {
        getRoles(watch('profile'), watch('company'));
      }
    } else if (watch('profile') === PROFILES.ROOT) {
      reset((prevValues) => ({
        ...prevValues,
        company: defaultValues.company
      }));
      setAreas([]);
      getRoles(watch('profile'), watch('company'));
    }
  }, [watch('profile'), watch('company')]);

  useEffect(() => {
    if (watch('profile') === PROFILES.AGENTE || watch('profile') === PROFILES.STANDARD_USER) {
      getAreas();
    }
    resetField('role');
    resetField('area');
    resetField('areas');
  }, [watch('company'), watch('profile')]);

  useEffect(() => {
    if (payload) {
      reset({
        _id: payload?._id || defaultValues._id,
        username: payload?.username ? payload.username : defaultValues.username,
        full_name: payload?.full_name ? payload.full_name : defaultValues.full_name,
        email: payload?.email ? payload.email : defaultValues.email,
        profile: payload?.profile ? payload.profile : defaultValues.profile,
        role: payload?.role ? payload.role : defaultValues.role,
        company: payload?.company ? payload.company : defaultValues.company,
        area: payload?.area ? payload.area : defaultValues.area,
        areas: payload?.areas ? payload.areas : defaultValues.areas
      });
      setValue('profile', payload.profile);
    }
  }, [payload]);
  return (
    <div className="modal fade" ref={modalRef} data-bs-backdrop="static">
      <div className="modal-dialog mw-1000px">
        <div className="modal-content">
          <div className="modal-header justify-content-end border-0 pb-0">
            <div
              className="btn btn-sm btn-icon btn-active-light-primary"
              onClick={() => closeModal()}
            >
              <i className="ki-duotone ki-cross fs-2x">
                <span className="path1" />
                <span className="path2" />
              </i>
            </div>
          </div>
          <form onSubmit={handleSubmit(submit)}>
            <div className="modal-body pt-0 pb-15 px-5 px-xl-20">
              <div className="mt-1 text-center">
                <h1 className="mb-3">
                  {watch('_id') ? (
                    <>
                      {t('users-page.modal-add-user.edit-user-title')}
                      <a className="link-primary fw-bold ms-2" href="#">
                        {payload?.username}
                      </a>
                    </>
                  ) : (
                    <> {t('users-page.modal-add-user.add-user-title')}</>
                  )}
                </h1>
                <div className="text-muted fw-semibold fs-5">
                  <span
                    dangerouslySetInnerHTML={{ __html: t('channels.sms.modal-C-Subtitle') }}
                  ></span>
                </div>
                <div className="separator border-2 my-5"></div>
              </div>
              <div className="row mt-10">
                <div className="col-lg-6 mb-10 mb-lg-0  h-100 p-10">
                  <Title
                    title={t('users-page.modal-add-user.data-user-title')}
                    subtitle={t('users-page.modal-add-user.data-user-text')}
                    icon="ki-notepad-edit"
                  />
                  <div className="fv-row mb-7">
                    <Input
                      label={t('users-page.modal-add-user.full-name-label')}
                      requiredIndicator="required"
                      placeholder={t('users-page.modal-add-user.full-name-placeholder')}
                      errors={errors.full_name}
                      {...register('full_name', {
                        required: true,
                        setValueAs: (value) => value.trim()
                      })}
                    />
                  </div>
                  <div className="fv-row mb-7">
                    <Input
                      label={t('users-page.modal-add-user.user-name-label')}
                      requiredIndicator="required"
                      placeholder={t('users-page.modal-add-user.user-name-placeholder')}
                      errors={errors.username}
                      {...register('username', {
                        required: true,
                        setValueAs: (value) => value.trim()
                      })}
                    />
                  </div>
                  <div className="fv-row mb-7">
                    <Input
                      label={t('users-page.modal-add-user.email-label')}
                      optional
                      placeholder={t('users-page.modal-add-user.email-placeholder')}
                      errors={errors.email}
                      {...register('email', {
                        required: false,
                        pattern: {
                          value: emailRegex,
                          message: t('companies-page.modal-add-company.error-email-message')
                        }
                      })}
                    />
                  </div>
                </div>
                <div className="col-lg-6 mb-10 mb-lg-0  h-100 p-10">
                  <Title
                    title={'Configuración de usuario'}
                    subtitle={'Elige el tipo de usuario, el área y el rol que desempeñará.'}
                    icon="ki-setting-2"
                    type="info"
                  />
                  <div className="col">
                    <div className="fv-row mb-8">
                      <label className="form-label required">
                        {t('users-page.modal-add-user.profile-label')}
                      </label>
                      <Controller
                        control={control}
                        name="profile"
                        rules={{ required: true }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <>
                            <Select
                              options={options}
                              isDisabled={payload}
                              noOptionsMessage={() => (
                                <div className="empty-select">{t('general.no-options')}</div>
                              )}
                              className={
                                error ? 'form-select-custom is-invalid' : 'form-select-custom'
                              }
                              classNamePrefix="form-select-custom"
                              placeholder={t('users-page.modal-add-user.profile-placeholder')}
                              onChange={(
                                selectedOption: { value: string; label: string } | null
                              ) => {
                                onChange(selectedOption ? selectedOption.value : null);
                                setValue('profile', selectedOption ? selectedOption.value : '');
                              }}
                              value={
                                options.find(
                                  (option: { value: string; label: string }) =>
                                    option.value === value
                                ) || null
                              }
                            />
                            <InvalidFeedback error={error} />
                          </>
                        )}
                      />
                    </div>
                  </div>
                  {(watch('profile') === PROFILES.ADMIN ||
                    watch('profile') === PROFILES.AGENTE ||
                    watch('profile') === PROFILES.STANDARD_USER) && (
                    <div className="row">
                      <div className="col">
                        <div className="fv-row mb-7">
                          <label className="form-label required">
                            {t('users-page.modal-add-user.company-label')}
                          </label>
                          <Controller
                            control={control}
                            name="company"
                            rules={{ required: true }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <>
                                <Select
                                  options={companies}
                                  noOptionsMessage={() => (
                                    <div className="empty-select">{t('general.no-options')}</div>
                                  )}
                                  className={
                                    error ? 'form-select-custom is-invalid' : 'form-select-custom'
                                  }
                                  classNamePrefix="form-select-custom"
                                  placeholder={t('users-page.modal-add-user.company-placeholder')}
                                  onChange={(
                                    selectedOption: { value: string; label: string } | null
                                  ) => {
                                    onChange(selectedOption ? selectedOption.value : null);
                                  }}
                                  value={
                                    companies.find((company: any) => company.value === value) ||
                                    null
                                  }
                                />
                                <InvalidFeedback error={error} />
                              </>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col">
                      <div className="fv-row mb-7">
                        <label className="form-label required">
                          {t('users-page.modal-add-user.rol-label')}
                        </label>
                        <Controller
                          control={control}
                          name="role"
                          rules={{ required: true }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <>
                              <Select
                                options={roles}
                                isDisabled={watch('profile') !== PROFILES.ROOT && !watch('company')}
                                noOptionsMessage={() => (
                                  <div className="empty-select">{t('general.no-options')}</div>
                                )}
                                className={
                                  error ? 'form-select-custom is-invalid' : 'form-select-custom'
                                }
                                classNamePrefix="form-select-custom"
                                placeholder={t('users-page.modal-add-user.rol-placeholder')}
                                onChange={(selectedOption) => {
                                  onChange(selectedOption.value);
                                }}
                                value={roles.find((option: any) => option.value === value) || null}
                              />
                              <InvalidFeedback error={error} />
                            </>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  {(watch('profile') === PROFILES.STANDARD_USER ||
                    watch('profile') === PROFILES.AGENTE) && (
                    <div className="row">
                      <div className="col">
                        <div className="fv-row mb-7">
                          <label className="form-label required">
                            {t('users-page.modal-add-user.areas-label')}
                          </label>
                          <Controller
                            control={control}
                            name={watch('profile') === PROFILES.STANDARD_USER ? 'areas' : 'area'}
                            rules={{ required: true }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <>
                                <Select
                                  options={areas}
                                  isMulti={isMulti()}
                                  isDisabled={watch('company') && watch('role') ? false : true}
                                  noOptionsMessage={() => (
                                    <div className="empty-select">{t('general.no-options')}</div>
                                  )}
                                  className={
                                    error ? 'form-select-custom is-invalid' : 'form-select-custom'
                                  }
                                  classNamePrefix="form-select-custom"
                                  placeholder={t('users-page.modal-add-user.areas-placeholder')}
                                  onChange={(selectedOptions) => {
                                    if (isMulti()) {
                                      onChange(
                                        selectedOptions
                                          ? selectedOptions.map((option: any) => option.value)
                                          : []
                                      );
                                    } else {
                                      onChange(selectedOptions ? selectedOptions.value : null);
                                    }
                                  }}
                                  value={
                                    isMulti()
                                      ? areas.filter((area: any) =>
                                          ((value as string[]) || []).includes(area.value)
                                        )
                                      : areas.find((area: any) => area.value === value) || null
                                  }
                                />
                                <InvalidFeedback error={error} />
                              </>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex flex-center flex-row-fluid pt-12">
                <button
                  type="button"
                  className="btn btn-light hover-scale"
                  onClick={() => closeModal()}
                >
                  {t('general.close')}
                </button>
                <Button
                  type="submit"
                  id="sendData"
                  className="btn btn-primary ms-3 hover-scale"
                  disabled={!isValid || watch('role') == ''}
                  isLoading={isSubmitting}
                  onClick={handleSubmit(submit)}
                >
                  {watch('_id') ? t('channels.wa.btn-update') : t('channels.wa.btn-add-modal')}
                  <i className="ki-duotone ki-send ms-2 fs-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddUser;
