import { useSession } from './use-session.hook';
import { useEffect, useMemo, useState } from 'react';

interface useHasAccessProps {
  view?: string[];
}

const useHasAccess = ({ view = [] }: useHasAccessProps) => {
  const { session } = useSession();
  const [isAllowed, setIsAllowed] = useState<any>();

  const profile = useMemo(() => session.profile.toLowerCase(), [session]);

  useEffect(() => {
    if (view.includes(profile)) {
      setIsAllowed(true);
    } else {
      setIsAllowed(false);
    }
  }, [view, profile]);

  return {
    isAllowed
  };
};

export default useHasAccess;
