import { useForm, Controller } from 'react-hook-form';
import Input from '../../components/forms/input.component';
import Select from 'react-select';
import Button from '../../components/forms/button.component';
import Title from '../../components/forms/titles.component';
import SimpleTitle from '../../components/forms/simple-titles.component';
import AdvancedCheckbox from '../../components/forms/advanced-checkbox.componenet';
import useStepper from '../../hooks/stepper.hook';
import InvalidFeedback from '../../components/forms/invalid-feedback.component';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useInputValidation from '../../hooks/input-validation.hook';
import { ChannelsService } from '../../services/channels.service';
import { AlertService } from '../../services/alert.service';
import { CompaniesService } from '../../services/companies.service';
import { APPS } from '../../constants';
import useModal from '../../hooks/close-modal.hook';

interface AddCompanyProps {
  show: boolean;
  onRequestClose: (refresh: boolean) => void;
}
interface Channel {
  _id: string;
  price: string;
}
interface Channels {
  [key: string]: Channel;
}

function AddCompany({ show, onRequestClose }: AddCompanyProps) {
  const { t } = useTranslation();
  const modalRef = useRef<HTMLDivElement>(null);
  useModal(modalRef, show);
  const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const { currentStep, setCurrentStep, resteStepper } = useStepper('add_new_area_stepper');
  const { priceRegex, handlePrice, validatePrice } = useInputValidation();

  const defaultValues = {
    company: {
      name: ''
    },
    company_user: {
      username: '',
      full_name: '',
      email: ''
    },
    area: {
      name: ''
    },
    area_user: {
      username: '',
      full_name: '',
      email: ''
    },
    channels: {
      whatsapp: {
        _id: '',
        price: ''
      },
      rcsConversational: {
        _id: '',
        price: ''
      },
      rcsSingle: {
        _id: '',
        price: ''
      },
      rcsBasic: {
        _id: '',
        price: ''
      },
      sms: {
        _id: '',
        price: ''
      }
    }
  };

  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isValid, isSubmitting }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const [channelConfig, setChannelConfig] = useState({
    whatsapp: false,
    rcsConversational: false,
    rcsSingle: false,
    rcsBasic: false,
    sms: false
  });

  const handleCheckboxChange = (name: any) => {
    setChannelConfig((prevConfig: any) => ({
      ...prevConfig,
      [name]: !prevConfig[name]
    }));
  };

  const [listData, setListaData] = useState({
    channelWhatsApp: [],
    channelRcsConversacional: [],
    channelRcsSingle: [],
    channelRcsBasic: [],
    channelSMS: []
  });

  const isStepValid = (step: number, watch: any) => {
    const values = watch();
    const isEmailValid = (email: any) => !email || emailRegex.test(email);
    switch (step) {
      case 1:
        return (
          !!watch('company.name') &&
          !!watch('company_user.username') &&
          !!watch('company_user.full_name') &&
          isEmailValid(values?.company_user?.email)
        );
      case 2:
        return (
          !!watch('area.name') &&
          !!watch('area_user.username') &&
          !!watch('area_user.full_name') &&
          isEmailValid(values?.area_user?.email)
        );
      case 3:
        return (
          (channelConfig.whatsapp
            ? !!values.channels.whatsapp._id &&
              !!values.channels.whatsapp.price &&
              validatePrice(values.channels.whatsapp.price)
            : true) &&
          (channelConfig.rcsConversational
            ? !!values.channels.rcsConversational._id &&
              !!values.channels.rcsConversational.price &&
              validatePrice(values.channels.rcsConversational.price)
            : true) &&
          (channelConfig.rcsSingle
            ? !!values.channels.rcsSingle._id &&
              !!values.channels.rcsSingle.price &&
              validatePrice(values.channels.rcsSingle.price)
            : true) &&
          (channelConfig.rcsBasic
            ? !!values.channels.rcsBasic._id &&
              !!values.channels.rcsBasic.price &&
              validatePrice(values.channels.rcsBasic.price)
            : true) &&
          (channelConfig.sms
            ? !!values.channels.sms._id &&
              !!values.channels.sms.price &&
              validatePrice(values.channels.sms.price)
            : true)
        );
      default:
        return false;
    }
  };

  const isCurrentStepValid = isStepValid(currentStep, watch);

  const getData = async (type?: string) => {
    let data: any;
    const types = type ? [type] : [APPS.CHANNEL_WHATSAPP];
    try {
      for (const currentType of types) {
        if (currentType === APPS.CHANNEL_WHATSAPP) {
          data = await ChannelsService.getAccountsWhatsApp();
          setListaData((prevData) => ({
            ...prevData,
            channelWhatsApp: data.map((item: any) => ({
              value: item._id,
              label: item.name
            }))
          }));
        }
      }
    } catch (error: any) {
      AlertService.toastError(t('general.atention'), error);
    }
  };

  const AddCompany = async (data: any) => {
    try {
      data.channels = cleanChannels(data.channels);
      await CompaniesService.saveCompany(data);
      resetForm();
      await onRequestClose(true);
      AlertService.toastSuccess(t('companies-page.alert-success-add'));
    } catch (error: any) {
      console.log('LoginPage.login.error:', error);
      AlertService.toastError(t('companies-page.alert-error-add'));
    }
  };

  const cleanChannels = (channels: Channels): Channels => {
    const defaultChannelId = '';
    const cleanedChannels: Channels = {};
    for (const key in channels) {
      if (channels[key]._id && channels[key]._id !== defaultChannelId) {
        cleanedChannels[key] = {
          _id: channels[key]._id,
          price: parseFloat(channels[key].price || '0.0').toString()
        };
      }
    }
    return cleanedChannels;
  };

  const resetForm = () => {
    reset();
    resteStepper.goFirst();
    setCurrentStep(1);
    setChannelConfig({
      whatsapp: false,
      rcsConversational: false,
      rcsSingle: false,
      rcsBasic: false,
      sms: false
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div aria-hidden="true" className="modal fade" ref={modalRef} data-bs-backdrop="static">
      <div className="modal-dialog mw-900px">
        <div className="modal-content">
          <div className="modal-header justify-content-end border-0 pb-0">
            <div
              className="btn btn-sm btn-icon btn-active-light-primary"
              onClick={() => (onRequestClose(false), resetForm())}
            >
              <i className="ki-duotone ki-cross fs-2x">
                <span className="path1" />
                <span className="path2" />
              </i>
            </div>
          </div>
          <div className="modal-body pt-0 pb-0 px-5 px-xl-20">
            <div className="mt-1 text-center">
              <h2>{t('companies-page.modal-add-company.title-add-company')}</h2>
              <div className="text-muted fw-semibold fs-5">
                <span
                  dangerouslySetInnerHTML={{ __html: t('channels.sms.modal-C-Subtitle') }}
                ></span>
              </div>
              <div className="separator border-2 my-5"></div>
            </div>
            <form className="form" onSubmit={handleSubmit(AddCompany)}>
              <div className="row mt-10">
                <div
                  className="stepper stepper-pills stepper-column d-flex flex-column flex-lg-row"
                  id="add_new_area_stepper"
                >
                  <div className="col-lg-5 mb-10 mb-lg-0 h-100 p-10">
                    <div className="stepper-nav">
                      <div className="stepper-item me-5 current" data-kt-stepper-element="nav">
                        <div className="stepper-wrapper d-flex align-items-center">
                          <div className="stepper-icon w-40px h-40px">
                            <i className="stepper-check fas fa-check" />
                            <span className="stepper-number">1</span>
                          </div>
                          <div className="stepper-label">
                            <h3 className="stepper-title">
                              {t(
                                'companies-page.modal-add-company.modal-steps.step-data-company.title'
                              )}
                            </h3>
                            <div className="stepper-desc">
                              {t(
                                'companies-page.modal-add-company.modal-steps.step-data-company.description'
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="stepper-line h-40px" />
                      </div>
                      <div className="stepper-item me-5" data-kt-stepper-element="nav">
                        <div className="stepper-wrapper d-flex align-items-center">
                          <div className="stepper-icon w-40px h-40px">
                            <i className="stepper-check fas fa-check" />
                            <span className="stepper-number">2</span>
                          </div>
                          <div className="stepper-label">
                            <h3 className="stepper-title">
                              {t(
                                'companies-page.modal-add-company.modal-steps.step-data-area.title'
                              )}
                            </h3>
                            <div className="stepper-desc">
                              {t(
                                'companies-page.modal-add-company.modal-steps.step-data-area.description'
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="stepper-line h-40px" />
                      </div>
                      <div className="stepper-item me-5" data-kt-stepper-element="nav">
                        <div className="stepper-wrapper d-flex align-items-center">
                          <div className="stepper-icon w-40px h-40px">
                            <i className="stepper-check fas fa-check" />
                            <span className="stepper-number">3</span>
                          </div>
                          <div className="stepper-label">
                            <h3 className="stepper-title">
                              {t(
                                'companies-page.modal-add-company.modal-steps.step-channels.title'
                              )}
                            </h3>
                            <div className="stepper-desc">
                              {t(
                                'companies-page.modal-add-company.modal-steps.step-channels.description'
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-7 rounded h-100 p-10">
                    <div className="mb-3">
                      {/*Primer stepper*/}
                      <div className="flex-column current" data-kt-stepper-element="content">
                        <div className="pb-10 pb-lg-12">
                          <Title
                            icon={'ki-briefcase'}
                            title={t('companies-page.modal-add-company.title-data-company')}
                            subtitle={t('companies-page.modal-add-company.item-data-company')}
                          />
                          <div className="fv-row mb-5">
                            <Input
                              label={t('companies-page.modal-add-company.label-name-company')}
                              requiredIndicator="required"
                              placeholder={t(
                                'companies-page.modal-add-company.subtitle-name-company'
                              )}
                              errors={errors?.company?.name}
                              {...register('company.name', { required: true })}
                            />
                          </div>
                        </div>
                        <div className="pb-10 pb-lg-12">
                          <Title
                            type={'info'}
                            icon={'ki-faceid'}
                            title={t('companies-page.modal-add-company.title-register-user')}
                            subtitle={t('companies-page.modal-add-company.subtitle-register-user')}
                          />
                          <div className="fv-row mb-5">
                            <Input
                              label={t('companies-page.modal-add-company.label-user')}
                              requiredIndicator="required"
                              placeholder={t('companies-page.modal-add-company.user-placeholder')}
                              errors={errors?.company_user?.username}
                              {...register('company_user.username', { required: true })}
                            />
                          </div>
                          <div className="fv-row mb-7">
                            <Input
                              label={t('companies-page.modal-add-company.label-full-name')}
                              requiredIndicator="required"
                              placeholder={t(
                                'companies-page.modal-add-company.full-name-placeholder'
                              )}
                              errors={errors?.company_user?.full_name}
                              {...register('company_user.full_name', { required: true })}
                            />
                          </div>
                          <div className="fv-row">
                            <Input
                              label={t('companies-page.modal-add-company.label-email')}
                              optional={true}
                              placeholder={t('companies-page.modal-add-company.email-placeholder')}
                              errors={errors?.company_user?.email}
                              {...register('company_user.email', {
                                required: false,
                                pattern: {
                                  value: emailRegex,
                                  message: t('companies-page.modal-add-company.error-email-message')
                                }
                              })}
                            />
                          </div>
                        </div>
                      </div>
                      {/*Segundo stepper*/}
                      <div className="flex-column" data-kt-stepper-element="content">
                        <div className="pb-10 pb-lg-12">
                          <Title
                            icon={'ki-data'}
                            title={t('companies-page.modal-add-company.title-data-area')}
                            subtitle={t('companies-page.modal-add-company.item-data-area')}
                          />
                          <div className="fv-row mb-5">
                            <Input
                              label={t('companies-page.modal-add-company.label-name-area')}
                              requiredIndicator="required"
                              placeholder={t(
                                'companies-page.modal-add-company.name-area-placeholder'
                              )}
                              errors={errors?.area?.name}
                              {...register('area.name', { required: true })}
                            />
                          </div>
                        </div>
                        <div className="pb-10 pb-lg-12">
                          <Title
                            type={'info'}
                            icon={'ki-faceid'}
                            title={t('companies-page.modal-add-company.title-register-user')}
                            subtitle={t('companies-page.modal-add-company.subtitle-register-area')}
                          />
                          <div className="fv-row mb-5">
                            <Input
                              label={t('companies-page.modal-add-company.label-user')}
                              requiredIndicator="required"
                              placeholder={t('companies-page.modal-add-company.user-placeholder')}
                              errors={errors?.area_user?.username}
                              {...register('area_user.username', { required: true })}
                            />
                          </div>
                          <div className="fv-row mb-7">
                            <Input
                              label={t('companies-page.modal-add-company.label-full-name')}
                              requiredIndicator="required"
                              placeholder={t(
                                'companies-page.modal-add-company.full-name-placeholder'
                              )}
                              errors={errors?.area_user?.full_name}
                              {...register('area_user.full_name', { required: true })}
                            />
                          </div>
                          <div className="fv-row">
                            <Input
                              label={t('companies-page.modal-add-company.label-email')}
                              optional={true}
                              placeholder={t('companies-page.modal-add-company.email-placeholder')}
                              errors={errors.area_user?.email}
                              {...register('area_user.email', {
                                required: false,
                                pattern: {
                                  value: emailRegex,
                                  message: t('companies-page.modal-add-company.error-email-message')
                                }
                              })}
                            />
                          </div>
                        </div>
                      </div>
                      {/*Tercer stepper*/}
                      <div className="flex-column mb-10" data-kt-stepper-element="content">
                        <SimpleTitle
                          title={t('areas-page.modal-add-area.stepper-3-title1')}
                          subtitle={t('areas-page.modal-add-area.stepper-3-subtitle1')}
                        />
                        <div className="scroll mh-500px">
                          <AdvancedCheckbox
                            icon={'ki-whatsapp'}
                            title={t('areas-page.modal-add-area.stepper-3-ch1')}
                            subtitle={t('areas-page.modal-add-area.stepper-3-ch1-subtitle')}
                            checked={channelConfig.whatsapp}
                            onChange={() => handleCheckboxChange('whatsapp')}
                            value={'1'}
                          />
                          {channelConfig.whatsapp && (
                            <>
                              <div className="fv-row mb-5">
                                <label className="form-label required">
                                  {' '}
                                  {t('areas-page.modal-add-area.stepper-3-input-accounts')}
                                </label>
                                <Controller
                                  control={control}
                                  name="channels.whatsapp._id"
                                  rules={{ required: !channelConfig.whatsapp }}
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error }
                                  }) => (
                                    <>
                                      <Select
                                        options={listData.channelWhatsApp}
                                        noOptionsMessage={() => (
                                          <div className="empty-select">
                                            {t('general.no-options')}
                                          </div>
                                        )}
                                        className={
                                          error
                                            ? 'form-select-custom is-invalid'
                                            : 'form-select-custom'
                                        }
                                        classNamePrefix="form-select-custom"
                                        placeholder={t(
                                          'areas-page.modal-add-area.stepper-3-input-accounts-placeholder'
                                        )}
                                        onChange={(
                                          selectedOption: { value: string; label: string } | null
                                        ) => {
                                          onChange(selectedOption ? selectedOption.value : null);
                                        }}
                                        value={
                                          listData.channelWhatsApp.find(
                                            (option: { value: string; label: string }) =>
                                              option.value === value
                                          ) || null
                                        }
                                      />
                                      <InvalidFeedback error={error} />
                                    </>
                                  )}
                                />
                              </div>
                              <div className="fv-row mb-10">
                                <Input
                                  label={t('areas-page.modal-add-area.stepper-3-input-price')}
                                  requiredIndicator="required"
                                  placeholder={t(
                                    'areas-page.modal-add-area.stepper-3-input-price-placeholder'
                                  )}
                                  onKeyDown={handlePrice}
                                  errors={errors?.channels?.whatsapp?.price}
                                  {...register('channels.whatsapp.price', {
                                    required: !channelConfig.whatsapp,
                                    pattern: {
                                      value: priceRegex,
                                      message: t('forms.invalid-FeedBack.valid-price')
                                    }
                                  })}
                                />
                              </div>
                            </>
                          )}
                          <AdvancedCheckbox
                            type="info"
                            icon={'ki-messages'}
                            title={t('areas-page.modal-add-area.stepper-3-ch2')}
                            subtitle={t('areas-page.modal-add-area.stepper-3-rcs-subtitle')}
                            checked={channelConfig.rcsConversational}
                            onChange={() => handleCheckboxChange('rcsConversational')}
                            value={'1'}
                          />
                          {channelConfig.rcsConversational && (
                            <>
                              <div className="fv-row mb-5">
                                <label className="form-label required">
                                  {t('areas-page.modal-add-area.stepper-3-input-agents')}
                                </label>
                                <Controller
                                  control={control}
                                  name="channels.rcsConversational._id"
                                  rules={{ required: !channelConfig.rcsConversational }}
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error }
                                  }) => (
                                    <>
                                      <Select
                                        options={listData.channelRcsConversacional}
                                        noOptionsMessage={() => (
                                          <div className="empty-select">
                                            {t('general.no-options')}
                                          </div>
                                        )}
                                        className={
                                          error
                                            ? 'form-select-custom is-invalid'
                                            : 'form-select-custom'
                                        }
                                        classNamePrefix="form-select-custom"
                                        placeholder={t(
                                          'areas-page.modal-add-area.stepper-3-input-agents-placeholder'
                                        )}
                                        onChange={(
                                          selectedOption: { value: string; label: string } | null
                                        ) => {
                                          onChange(selectedOption ? selectedOption.value : null);
                                        }}
                                        value={
                                          listData.channelRcsConversacional.find(
                                            (option: { value: string; label: string }) =>
                                              option.value === value
                                          ) || null
                                        }
                                      />
                                      <InvalidFeedback error={error} />
                                    </>
                                  )}
                                />
                              </div>
                              <div className="fv-row mb-10">
                                <Input
                                  label={t('areas-page.modal-add-area.stepper-3-input-price')}
                                  requiredIndicator="required"
                                  placeholder={t(
                                    'areas-page.modal-add-area.stepper-3-input-price-placeholder'
                                  )}
                                  onKeyDown={handlePrice}
                                  errors={errors?.channels?.rcsConversational?.price}
                                  {...register('channels.rcsConversational.price', {
                                    required: !channelConfig.rcsConversational,
                                    pattern: {
                                      value: priceRegex,
                                      message: t('forms.invalid-FeedBack.valid-price')
                                    }
                                  })}
                                />
                              </div>
                            </>
                          )}
                          <AdvancedCheckbox
                            type="info"
                            icon={'ki-messages'}
                            title={t('areas-page.modal-add-area.stepper-3-ch3')}
                            subtitle={t('areas-page.modal-add-area.stepper-3-rcs-subtitle')}
                            checked={channelConfig.rcsSingle}
                            onChange={() => handleCheckboxChange('rcsSingle')}
                            value={'1'}
                          />
                          {channelConfig.rcsSingle && (
                            <>
                              <div className="fv-row mb-5">
                                <label className="form-label required">
                                  {t('areas-page.modal-add-area.stepper-3-input-agents')}
                                </label>
                                <Controller
                                  control={control}
                                  name="channels.rcsSingle._id"
                                  rules={{ required: !channelConfig.rcsSingle }}
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error }
                                  }) => (
                                    <>
                                      <Select
                                        options={listData.channelRcsSingle}
                                        noOptionsMessage={() => (
                                          <div className="empty-select">
                                            No hay opciones disponibles.
                                          </div>
                                        )}
                                        className={
                                          error
                                            ? 'form-select-custom is-invalid'
                                            : 'form-select-custom'
                                        }
                                        classNamePrefix="form-select-custom"
                                        placeholder={t(
                                          'areas-page.modal-add-area.stepper-3-input-agents-placeholder'
                                        )}
                                        onChange={(
                                          selectedOption: { value: string; label: string } | null
                                        ) => {
                                          onChange(selectedOption ? selectedOption.value : null);
                                        }}
                                        value={
                                          listData.channelRcsSingle.find(
                                            (option: { value: string; label: string }) =>
                                              option.value === value
                                          ) || null
                                        }
                                      />
                                      <InvalidFeedback error={error} />
                                    </>
                                  )}
                                />
                              </div>
                              <div className="fv-row mb-10">
                                <Input
                                  label={t('areas-page.modal-add-area.stepper-3-input-price')}
                                  requiredIndicator="required"
                                  placeholder={t(
                                    'areas-page.modal-add-area.stepper-3-input-price-placeholder'
                                  )}
                                  onKeyDown={handlePrice}
                                  errors={errors.channels?.rcsSingle?.price}
                                  {...register('channels.rcsSingle.price', {
                                    required: !channelConfig.rcsSingle,
                                    pattern: {
                                      value: priceRegex,
                                      message: t('forms.invalid-FeedBack.valid-price')
                                    }
                                  })}
                                />
                              </div>
                            </>
                          )}
                          <AdvancedCheckbox
                            type="info"
                            icon={'ki-messages'}
                            title={t('areas-page.modal-add-area.stepper-3-ch4')}
                            subtitle={t('areas-page.modal-add-area.stepper-3-rcs-subtitle')}
                            checked={channelConfig.rcsBasic}
                            onChange={() => handleCheckboxChange('rcsBasic')}
                            value={'1'}
                          />
                          {channelConfig.rcsBasic && (
                            <>
                              <div className="fv-row mb-5">
                                <label className="form-label required">
                                  {t('areas-page.modal-add-area.stepper-3-input-agents')}
                                </label>
                                <Controller
                                  control={control}
                                  name="channels.rcsBasic._id"
                                  rules={{ required: !channelConfig.rcsBasic }}
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error }
                                  }) => (
                                    <>
                                      <Select
                                        options={listData.channelRcsBasic}
                                        noOptionsMessage={() => (
                                          <div className="empty-select">
                                            {t('general.no-options')}
                                          </div>
                                        )}
                                        className={
                                          error
                                            ? 'form-select-custom is-invalid'
                                            : 'form-select-custom'
                                        }
                                        classNamePrefix="form-select-custom"
                                        placeholder={t(
                                          'areas-page.modal-add-area.stepper-3-input-agents-placeholder'
                                        )}
                                        onChange={(
                                          selectedOption: { value: string; label: string } | null
                                        ) => {
                                          onChange(selectedOption ? selectedOption.value : null);
                                        }}
                                        value={
                                          listData.channelRcsBasic.find(
                                            (option: { value: string; label: string }) =>
                                              option.value === value
                                          ) || null
                                        }
                                      />
                                      <InvalidFeedback error={error} />
                                    </>
                                  )}
                                />
                              </div>
                              <div className="fv-row mb-10">
                                <Input
                                  label={t('areas-page.modal-add-area.stepper-3-input-price')}
                                  requiredIndicator="required"
                                  placeholder={t(
                                    'areas-page.modal-add-area.stepper-3-input-price-placeholder'
                                  )}
                                  onKeyDown={handlePrice}
                                  errors={errors.channels?.rcsBasic?.price}
                                  {...register('channels.rcsBasic.price', {
                                    required: !channelConfig.rcsBasic,
                                    pattern: {
                                      value: priceRegex,
                                      message: t('forms.invalid-FeedBack.valid-price')
                                    }
                                  })}
                                />
                              </div>
                            </>
                          )}
                          <AdvancedCheckbox
                            type="warning"
                            icon={'ki-message-notif'}
                            title={t('areas-page.modal-add-area.stepper-3-ch5')}
                            subtitle={t('areas-page.modal-add-area.stepper-3-ch5-subtitle')}
                            checked={channelConfig.sms}
                            onChange={() => handleCheckboxChange('sms')}
                            value={'1'}
                          />
                          {channelConfig.sms && (
                            <>
                              <div className="fv-row mb-5">
                                <label className="form-label required">
                                  {t('areas-page.modal-add-area.stepper-3-input-mask')}
                                </label>
                                <Controller
                                  control={control}
                                  name="channels.sms._id"
                                  rules={{ required: !channelConfig.sms }}
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error }
                                  }) => (
                                    <>
                                      <Select
                                        options={listData.channelSMS}
                                        noOptionsMessage={() => (
                                          <div className="empty-select">
                                            {t('general.no-options')}
                                          </div>
                                        )}
                                        className={
                                          error
                                            ? 'form-select-custom is-invalid'
                                            : 'form-select-custom'
                                        }
                                        classNamePrefix="form-select-custom"
                                        placeholder={t(
                                          'areas-page.modal-add-area.stepper-3-input-mask-placeholder'
                                        )}
                                        onChange={(
                                          selectedOption: { value: string; label: string } | null
                                        ) => {
                                          onChange(selectedOption ? selectedOption.value : null);
                                        }}
                                        value={
                                          listData.channelSMS.find(
                                            (option: { value: string; label: string }) =>
                                              option.value === value
                                          ) || null
                                        }
                                      />
                                      <InvalidFeedback error={error} />
                                    </>
                                  )}
                                />
                              </div>
                              <div className="fv-row mb-10">
                                <Input
                                  label={t('areas-page.modal-add-area.stepper-3-input-price')}
                                  requiredIndicator="required"
                                  placeholder={t(
                                    'areas-page.modal-add-area.stepper-3-input-price-placeholder'
                                  )}
                                  onKeyDown={handlePrice}
                                  errors={errors.channels?.sms?.price}
                                  {...register('channels.sms.price', {
                                    required: !channelConfig.sms,
                                    pattern: {
                                      value: priceRegex,
                                      message: t('forms.invalid-FeedBack.valid-price')
                                    }
                                  })}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>

                    {/*Botones*/}
                    <div className="d-flex flex-center flex-row-fluid pt-0">
                      <div className="d-flex flex-stack mb-10">
                        <div className="me-15">
                          <Button
                            type="button"
                            typeStepperBtn="previous"
                            id="previous"
                            className="btn btn-lg btn-secondary w-100 mb-5 hover-scale"
                          >
                            <i className="ki-duotone ki-double-left ms-2 fs-1">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                            {t('general.back')}
                          </Button>
                        </div>
                        <div>
                          <div className="me-2">
                            <Button
                              type="submit"
                              typeStepperBtn="submit"
                              id="sendData"
                              className="btn btn-lg btn-primary w-100 mb-5 hover-scale"
                              disabled={!isValid || !isCurrentStepValid}
                              isLoading={isSubmitting}
                            >
                              {t('general.send')}
                              <i className="ki-duotone ki-send ms-2 fs-1">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                            </Button>
                            <Button
                              type="button"
                              className="btn btn-lg btn-primary w-100  hover-scale"
                              id="next"
                              typeStepperBtn="next"
                              disabled={!isCurrentStepValid}
                            >
                              {t('general.continue')}
                              <i className="ki-duotone ki-double-right ms-2 fs-1">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCompany;
